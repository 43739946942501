import React, {useState, useCallback} from 'react';
import { motion, AnimatePresence } from "framer-motion"
import useWindowDimensions from '../../utils/window';
// Styles
import styles from './index.module.scss';
import closeIcon from '../../images/close.svg';

// Assets


export const PopupWindow = ({open, title, onClose, children, windowStyles}) => {

  const { height, width } = useWindowDimensions();

  const customStyles = (width > 700)?{...windowStyles}:{}

  return(
    open && (
      <AnimatePresence>
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.documentsWindowContainer}
      >
          <div className={styles.documentsWindow} style={customStyles}>
            <div className={styles.documentsWindowTitle}>{title}</div>
            <img src={closeIcon} onClick={(e)=>{onClose()}} className={styles.closeIcon} />
            <div className={styles.documentsWindowContent}>
              {children}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  )
}
