import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

const content = () => {


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>Types Of Restaurants In Spain</div>
        <div className={styles.paragraph}>
        Spain's rich culinary landscape is as diverse as its regions, each offering unique flavors, ingredients, and dining experiences. <br/>
        From bustling cities to picturesque villages, the types of restaurants in Spain reflect its cultural heritage and regional specialties. <br/>
        Here’s a closer look at the various types of eateries you can find throughout the country.
        </div>

        <div className={styles.subtitle}>Tapas Bars</div>
        <div className={styles.paragraph}>
        Tapas bars are iconic to Spanish dining. These establishments specialize in small dishes that encourage sharing, making them perfect for social gatherings. <br/>
        Menu items typically include patatas bravas (fried potatoes with spicy sauce), croquetas (fried dough filled with various ingredients), and jamón ibérico (cured ham). <br/>
        Ranging from casual to upscale, tapas bars offer a laid-back atmosphere where patrons can enjoy a drink and a variety of flavors.
        </div>

        <div className={styles.subtitle}>Cafeterías</div>
        <div className={styles.paragraph}>
        Cafeterías are informal venues primarily serving breakfast and light meals. <br/>
        They are perfect for a quick snack or a leisurely coffee break. <br/>
        Popular offerings include churros served with chocolate, tostadas (toasted bread topped with tomato and olive oil), and a range of pastries. <br/>
        Cafeterías often serve as local meeting spots for friends and families.
        </div>

        <div className={styles.subtitle}>Restaurantes de Comida Tradicional</div>
        <div className={styles.paragraph}>
        These traditional restaurants focus on regional specialties and classic Spanish cuisine. <br/>
        Each region showcases its unique dishes; for example, paella is a must-try in Valencia, while cocido (a hearty stew) is a favorite in Madrid. <br/>
        These establishments emphasize fresh, local ingredients and traditional cooking methods, creating an authentic culinary experience.
        </div>

        <div className={styles.subtitle}>Asadores</div>
        <div className={styles.paragraph}>
        Asadores are known for their grilled meats, particularly in regions like Galicia and Castilla y León. <br/>
        These restaurants feature wood-fired grills and specialize in dishes like cochinillo (suckling pig) and cordero asado (roast lamb). <br/>
        The smoky flavors and tender meats make asadores a favorite among meat lovers. 
        </div>

        <div className={styles.subtitle}>Marisquerías</div>
        <div className={styles.paragraph}>
        Focusing on seafood, marisquerías are especially prevalent in coastal areas. Diners can indulge in an array of fresh shellfish, fish dishes, and specialties like paella de marisco (seafood paella). <br/>
        These restaurants often highlight local catches, offering a true taste of the sea.
        </div>

        <div className={styles.subtitle}>Bodegas</div>
        <div className={styles.paragraph}>
        Bodegas are wine cellars that often double as restaurants, particularly in wine-producing regions like La Rioja. <br/>
        They serve hearty traditional dishes paired with local wines. <br/>
        The rustic atmosphere, filled with barrels and wine bottles, creates an inviting setting for a meal.
        </div>

        <div className={styles.subtitle}>Cocinas de Autor</div>
        <div className={styles.paragraph}>
        Representing the modern culinary movement, cocinas de autor are innovative restaurants where renowned chefs create unique dishes. <br/>
        These establishments often blend traditional Spanish flavors with international influences, presenting tasting menus that emphasize creativity and artistic presentation.
        </div>

        <div className={styles.subtitle}>Latin-American Restaurants</div>
        <div className={styles.paragraph}>
        Latin American restaurants in Spain offer a vibrant and flavorful exploration of the diverse cuisines from across the region. <br/>
        From Argentinian parrillas serving perfectly grilled steaks and traditional empanadas to Peruvian eateries featuring fresh ceviche and hearty lomo saltado, these establishments bring a taste of Latin America to Spanish shores. <br/>
        Additionally, many of these restaurants celebrate the rich culinary traditions of countries like Colombia, Mexico, and Cuba, often showcasing dishes like tacos, arepas, and ropa vieja. <br/>
        The ambiance is typically lively and colorful, often accompanied by music that reflects the cultural heritage of the dishes. <br/>
        This culinary fusion enriches Spain's gastronomic landscape, allowing diners to savor the unique flavors and cultural stories that each dish embodies.
        </div>

      


        <div className={styles.paragraph}>
        Spain's restaurant scene is a delightful tapestry of flavors and dining styles, reflecting the country's diverse regions and culinary traditions. <br/>
        Whether you’re indulging in tapas, savoring a seafood feast, or exploring innovative cuisine, each type of restaurant offers a unique window into Spain's rich gastronomic culture. <br/>
        Embarking on this culinary journey is not just about the food; it’s also about experiencing the warmth and hospitality that define Spanish dining.
        </div>

      </div>
    </EmojiProvider>
  )
}

export default content
