import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, deleteEntity } from '../utils/request';


export const loadProfile = createAsyncThunk('principal/loadProfile', async ({token}) => {
  const response = await get('/client/profile',{token})
  if(response.data?.success){
    
  }
  return response.data
})

export const getMessages = createAsyncThunk('principal/getMessages', async () => {
  const response = await get('/clients/messages',{})
  if(response.data?.success){
    
  }
  return response.data
})

export const getAccountantMessages = createAsyncThunk('principal/getAccountantMessages', async () => {
  const response = await get('/accountants/messages',{})
  if(response.data?.success){
    
  }
  return response.data
})

export const support = createAsyncThunk('principal/support', async ({message}) => {
  const response = await post('/clients/support',{message})
  if(response.data?.success){
    
  }
  return response.data
})


export const createDocument = createAsyncThunk('principal/createDocument', async ({file, notes, documentId}) => {
  const response = await post('/clients/documents',{file,notes,usesFiles:true,documentId})
  console.log(file)
  if(response.data?.success){
    
  }
  return response.data
})

export const deleteDocument = createAsyncThunk('principal/deleteDocument', async ({id}) => {
  const response = await deleteEntity('/clients/documents/'+id,{})
  if(response.data?.success){
    
  }
  return response.data
})

export const createInvoice = createAsyncThunk('principal/createInvoice', async ({fileName, file, notes}) => {
  const response = await post('/clients/invoices',{fileName, file,usesFiles:true})
  console.log(file)
  if(response.data?.success){
    
  }
  return response.data
})

export const deleteInvoice = createAsyncThunk('principal/deleteInvoice', async ({id}) => {
  const response = await deleteEntity('/clients/invoices/'+id,{})
  if(response.data?.success){
    
  }
  return response.data
})

export const callAssistant = createAsyncThunk('principal/callAssistant', async ({query},{ getState }) => {
  const state = getState()
  const response = await post('/clients/assistant',{query,threadId:state.threadId})
  if(response.data?.success){
    
  }
  return response.data
})


export const principalSlice = createSlice({
  name: 'principal',
  initialState: {
    user: null,
    status: 'initial',
    assistantMessages:[],
    messages:[]
  },
  reducers: {
    pushMessage: (state, value) => {
      state.assistantMessages = [...state.assistantMessages,{sender:'me',text:value.payload}]
    },
    pushAccountantMessage: (state, value) => {
      console.log(value)
      state.messages = [...state.messages,value.payload]
    },
    updateMenuStatus: (state, value) => {
      state.menuOpen = !state.menuOpen
    },
    updateMessagesStatus: (state, value) => {
      state.messagesOpen = !state.messagesOpen
    }
  },
  extraReducers: (builder)=>{
    builder
      .addCase(loadProfile.fulfilled, (state, action) => {
        if(action.payload.success){
          state.user = action.payload.client

        }
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        if(action.payload && action.payload.success){
          state.documents = action.payload.documents
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        if(action.payload.success){
          state.documents = action.payload.documents
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        if(action.payload && action.payload.success){
          state.invoices = [...action.payload.invoices]
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(support.fulfilled, (state, action) => {
        if(action.payload && action.payload.success){

        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        console.log('FHCBSHFSHS')
        console.log(action.payload)
        if(action.payload.success){
          state.invoices = [...action.payload.invoices]
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(callAssistant.fulfilled, (state, action) => {
        if(action.payload.success){
          state.assistantMessages = [...state.assistantMessages,{sender:'assistant',text:action.payload.response}]
          state.threadId = action.payload.threadId
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        if(action.payload && action.payload.success){
          state.messages = action.payload.messages
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
      .addCase(getAccountantMessages.fulfilled, (state, action) => {
        if(action.payload && action.payload.success){
          state.messages = action.payload.messages
        }else{
          state.error = 'Something went wrong. Please try again.'
        }
      })
  }
})

principalSlice.async$ = {
  loadProfile,
  createDocument,
  deleteDocument,
  createInvoice,
  deleteInvoice,
  callAssistant,
  getMessages,
  getAccountantMessages,
  support
}

export default principalSlice