
import styles from './styles.module.scss'
import { currencies, getCurrencySymbol } from '../../utils/currencies.js'

export const PDF = ({ref, zIndex, name, from, to, terms, notes, currency, invoiceItems, sentDate, dueDate, tax, senderVAT, receiverVAT})=>{

    const getSubtotal = ()=>{
        let subtotal = 0
    
        for(let i = 0;i < invoiceItems.length;i++){
          subtotal += (invoiceItems[i].price * invoiceItems[i].quantity)
        }
    
        return (subtotal)
      }
    
      const getInvoiceTotal = ()=>{
        let subtotal = 0
    
        for(let i = 0;i < invoiceItems.length;i++){
          subtotal += (invoiceItems[i].price * invoiceItems[i].quantity)
        }
    
        return (subtotal + ((subtotal * tax)/100))
      }

    return(
        <div id={'pdf-download'} className={styles.pdf} ref={ref} style={{zIndex}}>
        <div className={styles.pdfInvoiceColumns}>
                <div className={styles.pdfInvoiceColumn}>
                  <div className={styles.createInvoiceRowLeft}>
                    <div className={styles.invoiceTitleLeft}>From</div>
                    <div className={styles.pdfAddressLeft}>{from}</div>
                  </div>
                    
                  <div className={styles.createInvoiceRowLeft}>
                    <div className={styles.invoiceTitleLeft}>To</div>
                    <div className={styles.pdfAddressLeft}>{to}</div>
                  </div>
                </div>
                <div className={styles.pdfInvoiceColumn}>
                  <div className={styles.pdfIntroRight}>
                    <div className={styles.pdfQuestionRight}>Invoice #</div>
                    <div className={styles.pdfAnswerRight}>{name}</div>
                  </div>
                  
                  <div className={styles.pdfIntroRight}>
                  <div className={styles.pdfDateRight}>Sent Date</div>
                  <div className={styles.pdfAnswerRight} style={{letterSpacing:'1px'}}>{sentDate}</div>
                  </div>

                  <div className={styles.pdfIntroRight}>
                    <div className={styles.pdfDateRight}>Due Date</div>
                    <div className={styles.pdfAnswerRight} style={{letterSpacing:'1px'}}>{dueDate}</div>
                  </div>

                  <div className={styles.pdfIntroRight}>
                    <div className={styles.pdfDateRight}>Sender VAT</div>
                    <div className={styles.pdfAnswerRight} style={{letterSpacing:'1px'}}>{senderVAT}</div>
                  </div>

                  <div className={styles.pdfIntroRight}>
                    <div className={styles.pdfDateRight}>Receiver VAT</div>
                    <div className={styles.pdfAnswerRight} style={{letterSpacing:'1px'}}>{receiverVAT}</div>
                  </div>

                </div>
              </div>

              <br/><br/><br/>
              <table className={styles.pdfInvoicePdfTable}>
              <tr style={{width:'435pt',display:'flex',flexDirection:'row',paddingBottom:'10pt'}}>
                  <td style={{flex:1,fontWeight:'bold'}}>
                    Item
                  </td>
                  <td style={{flex:1,fontWeight:'bold'}}>
                   Price
                  </td>
                  <td style={{flex:1,fontWeight:'bold'}}>
                   Quantity
                  </td>
                </tr>
                {
                  invoiceItems.map((item,itemIndex)=>{
                    return(<tr style={{width:'435pt',display:'flex',flexDirection:'row',fontSize:'14px'}}>
                      <td style={{flex:1}}>
                       {item.name}
                      </td>
                      <td style={{flex:1}}>
                        {getCurrencySymbol(currency)} {item.price}
                      </td>
                      <td style={{flex:1}}>
                        {item.quantity}
                      </td>
                    </tr>)
                  })
                }
                <tr style={{width:'435pt',display:'flex',flexDirection:'row',paddingBottom:'10pt'}}>
                  <td style={{flex:1,fontWeight:'bold'}}>
                  </td>
                  <td style={{flex:1,fontWeight:'bold'}}>
                  </td>
                  <td style={{flex:1,fontWeight:'bold'}}>
                  </td>
                </tr>
              </table>
              <br/><br/>
              <div className={styles.pdfCreateInvoiceRowRight}>
                  <div className={styles.pdfAmountRight}><b>Subtotal: {getCurrencySymbol(currency)}{getSubtotal()}</b></div>
                  <br/>
                  <div className={styles.pdfAmountRight}>Tax: {tax}%</div>
                  <br/>
                  <div className={styles.pdfAmountRight}><b>Total Due: {getCurrencySymbol(currency)}{getInvoiceTotal()}</b></div>
              </div>
              <br/><br/>
                <div className={styles.pdfCreateInvoiceRowLeft}>
                <div className={styles.pdfQuestionRight}>Notes</div>
                <div className={styles.pdfAnswerRight}>{notes}</div>
                </div>
                <br/>
                <div className={styles.pdfCreateInvoiceRowLeft}>
                <div className={styles.pdfQuestionRight}>Terms</div>
                <div className={styles.pdfAnswerRight}>{terms}</div>
              </div>
        </div>
    )
}