
import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import styles from './styles.module.scss'
import { socket } from '../../utils/socket'
import { getSentTimeLocal } from '../../utils/getSentTimeLocal'
import Cookies from "universal-cookie"
import store from '../../store'
import principalSlice from '../../slices/principalSlice'
import { getLastMessageShortenedClient, firstToUpper, lastMessageSeenClient } from '../../utils/getLatestMessage'
import closeIcon from '../../images/close.svg'

// Assets
import defaultProfilePicture from '../../images/user.png'

export const Client = () => {
  const navigate = useNavigate();
  const accountant = useSelector(state => state.authSlice?.accountant)
  const lawyer = useSelector(state => state.authSlice?.lawyer)
  const user = useSelector(state => state.authSlice?.user)
  const messages = useSelector(state => state.principalSlice?.messages)
  const messagesOpen = useSelector(state => state.principalSlice?.messagesOpen)
  const [inputText, setInputText] = useState([])
  const [chosenUser, setChosenUser] = useState(accountant)
  

  const initSocket = async()=>{
    console.log('CONNECTED SOCKET 2024-11')
    const cookies = new Cookies()
    const token = await cookies.get("token")
    await store.dispatch(principalSlice.async$.getMessages())
    socket.auth = { token }
    socket.on("connect", () => { console.log(`User connected: ${socket.id}`) })
    socket.on("disconnect", () => { console.log(`User disconnected: ${socket.id}`) })
    socket.on('send-message',async(message) => {
      let elem = document.getElementById('client-messages')
      await store.dispatch(principalSlice.actions.pushAccountantMessage(message))
      elem.scrollTop = elem.scrollHeight
    })
    
    socket.connect()
    let elem = document.getElementById('client-messages');
    elem.scrollTop = elem.scrollHeight;
  }

  const sendRead = (accountantId)=>{
    socket.emit("client-read", {
      accountantId
    })
  }

  const sendMessage = ()=>{
    socket.emit("send-message", {
      content:inputText,
      to: user.id+'-'+chosenUser.type
    })
  }

  useEffect(()=>{
    initSocket()
  },[])



  return (
    <div className={styles.screen}>
        <ClientMenu activeScreen={'Messages'} />
        <div className={styles.content}>
          <Header name={user?.name} />
          <div className={styles.accountantMessages}>
            <div className={styles.clientsListWindow}>
              <div className={styles.messagesTitle}>My Messages</div>
              <br/><br/>
                {
                  [lawyer, accountant].map((client)=>{
                    return (
                      <div className={styles.client} onClick={async(e)=>{ 
                        await setChosenUser(client)
                        store.dispatch(principalSlice.actions.updateMessagesStatus())
                        let elem = document.getElementById('client-messages');
                        elem.scrollTop = elem.scrollHeight;
                      }}>
                        <img src={client?.profileImage || defaultProfilePicture} className={styles.clientProfileImage} />
                        <div className={styles.clientText}>
                          <div className={styles.clientName}>{client?.name} ({firstToUpper(client?.type)})</div>
                          <div className={styles.clientMessage}>{getLastMessageShortenedClient(messages, client)}</div>
                        </div>
                      </div>
                    )
                  })
                }
            </div>

            {<div className={styles.accountantMessagesWindow}>
              <div className={styles.messagesHeader}>
                <img src={accountant?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                {'Messages With '+chosenUser?.name}
              </div>
              <div id='client-messages' className={styles.messagesContainer}>
                {
                  messages.filter((m)=>{ return m.accountantId == chosenUser?.id}).map((message)=>{
                    return(
                      <div className={(message.senderId === 1)?styles.sentMessageRow:styles.receivedMessageRow}>
                        <div className={(message.senderId === 1)?styles.sentMessage:styles.receivedMessage}>{message.text}</div>
                        <div className={(message.senderId === 1)?styles.sentTimeRight:styles.sentTimeLeft}>{getSentTimeLocal(message.sent)}</div>
                      </div>
                    )
                  })
                }
                {
                  (lastMessageSeenClient(messages,chosenUser))?
                  <div className={styles.sentMessageRow}>
                    <div className={styles.seen}>Seen By {chosenUser.name}</div>
                  </div>
                    :
                  <></>
                }
              </div>
              <div className={styles.inputContainer}>
                <form className={styles.form} onSubmit={async(event)=>{
                  event.preventDefault()
                  sendMessage()
                  setInputText('')
                  document.getElementById('message-textbox').value = ''
                }}>
                  <input id='message-textbox' placeholder={"Type a message..."} type="text" className={styles.input} value={inputText} onChange={(event)=>{setInputText(event.target.value)}} />
                  <input type='submit' className={styles.sendMessage} value={'Send'} />
                </form>
            </div>
            </div>
            }
            {(messagesOpen)?
              <div className={styles.accountantMessagesWindowMobile}>
              <div className={styles.messagesHeader}>
                <img src={accountant?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                {'Messages With '+chosenUser?.name}
                <img src={closeIcon} onClick={(e)=>{ store.dispatch(principalSlice.actions.updateMessagesStatus()) }} className={styles.closeIcon} />
              </div>
              <div id='client-messages' className={styles.messagesContainer}>
                {
                  messages.filter((m)=>{ return m.accountantId == chosenUser?.id}).map((message)=>{
                    return(
                      <div className={(message.senderId === 1)?styles.sentMessageRow:styles.receivedMessageRow}>
                        <div className={(message.senderId === 1)?styles.sentMessage:styles.receivedMessage}>{message.text}</div>
                        <div className={(message.senderId === 1)?styles.sentTimeRight:styles.sentTimeLeft}>{getSentTimeLocal(message.sent)}</div>
                      </div>
                    )
                  })
                }
                {
                  (lastMessageSeenClient(messages,chosenUser))?
                  <div className={styles.sentMessageRow}>
                    <div className={styles.seen}>Seen By {chosenUser.name}</div>
                  </div>
                    :
                  <></>
                }
              </div>
              <div className={styles.inputContainer}>
                <form className={styles.form} onSubmit={async(event)=>{
                  event.preventDefault()
                  sendMessage()
                  setInputText('')
                  document.getElementById('message-textbox').value = ''
                }}>
                  <input id='message-textbox' placeholder={"Type a message..."} type="text" className={styles.input} value={inputText} onChange={(event)=>{setInputText(event.target.value)}} />
                  <input type='submit' className={styles.sendMessage} value={'Send'} />
                </form>
            </div>
            </div>
            :
            <></>
            }
          </div>
        </div>
     </div>
  )
}