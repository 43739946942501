import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

const content = () => {

  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>A Guide to Sending Invoices As A Freelancer in Spain</div>
        <div className={styles.paragraph}>
        Creating invoices as a freelancer in Spain involves adhering to specific legal requirements to ensure compliance with tax regulations. <br/>
        Here’s a summary of the essential rules:
        </div>
        <br/><br/>
        <div className={styles.subtitle}>1. Basic Information Required</div>
        <div className={styles.paragraph}>
        An invoice must include the following details::
        <br/>
        <list>
          <li><b>Invoice Number:</b> Sequential and unique for each invoice.</li>
          <li><b>Date of Issue:</b>  The date when the invoice is created.</li>
          <li><b>Freelancer’s Information:</b>  Name, address, and tax identification number (NIF).</li>
          <li><b>Client’s Information:</b> Name, address, and NIF/CIF (if applicable).</li>
          <li><b>Description of Services:</b> A clear description of the services provided.</li>
          <li><b>Total Amount Due:</b> The total cost, including applicable taxes.</li>
        </list>
        <br/>
        Consider what services are important to you—such as online banking, English-speaking staff, and branch locations—when selecting a bank.
        </div>

        <div className={styles.subtitle}>2. Tax Obligations</div>
        <div className={styles.paragraph}>
        <br/>
        <list>
          <li><b>IVA (Value Added Tax):</b> Most services are subject to a VAT rate of 21%, but some may have reduced rates (10% or 4%). Ensure to specify the tax rate applied and the total VAT amount.</li>
          <li><b>IRPF (Personal Income Tax):</b> Freelancers may need to withhold a percentage (typically 15% for established freelancers, 7% for new ones) from their invoices for income tax purposes.</li>
        </list>
        <br/>Your accountant can help explain more of this to you.
        </div>

        <div className={styles.subtitle}>3. Format and Language</div>
        <div className={styles.paragraph}>
        <br/>
        <list>
          <li>Invoices can be issued in either Spanish or any other official language, but Spanish is the most common.</li>
          <li>They can be created in paper or digital formats, but digital invoices must comply with electronic invoicing regulations.</li>
         </list>
        </div>

        <div className={styles.subtitle}>4. Retention and Record Keeping</div>
        <div className={styles.paragraph}>
        <list>
          <li>Keep copies of all issued invoices for at least four years, as required by the Spanish tax authority (Agencia Tributaria).</li>
          <li>Organize invoices systematically for easier access during tax declarations or audits.</li>
         </list>
        </div>

        <div className={styles.subtitle}>5. Timeliness</div>
        <div className={styles.paragraph}>
        Invoices should be issued as soon as services are rendered or products are delivered, adhering to any agreed-upon payment terms.
        </div>

        <div className={styles.subtitle}>6. Additional Considerations</div>
        <div className={styles.paragraph}>
        <list>
          <li>Include payment methods and terms to clarify how clients can settle their invoices.</li>
          <li>Consider adding an invoice footer with additional details, such as terms and conditions or late payment fees.</li>
         </list>
        </div>

        <div className={styles.paragraph}>
        Following these guidelines will help freelancers in Spain create compliant invoices, streamline their accounting processes, and maintain good relationships with clients. 
        <br/> For more detailed advice, consulting a tax professional is advisable.
        </div>

      

      </div>
    </EmojiProvider>
  )
}

export default content

