import React from 'react';

// Styles
import styles from './index.module.scss';

// Assets

export const SideMenuItem = ({href,icon,text,isActive})=>{
  return(
    <a href={href}>
      <div className={isActive?styles.sideMenuItemMainActive:styles.sideMenuItemMain}>
        <div className={isActive?styles.sideMenuItemMainBarActive:styles.sideMenuItemMainBar}></div>
        <img src={icon} className={styles.sideMenuItemIcon} />
        <div className={styles.sideMenuItemTextMain}>{text}</div>
      </div>
    </a>
  )
}
