
import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import { PopupWindow } from '../../components/PopupWindow';
import styles from './styles.module.scss'
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import getDateTimeString from '../../utils/getDateTimeString';
import { ClipLoader } from "react-spinners";
import {TextInput} from '../../components/TextInput/index.js';

import principalSlice from '../../slices/principalSlice'
import store from '../../store'

export const Client = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const documents = useSelector(state => state.principalSlice?.documents)
  const initialDocuments = useSelector(state => state.authSlice?.documents)
  const [newDocumentWindowOpen, setNewDocumentWindowOpen] = useState(false)
  const [newDocumentName, setNewDocumentName] = useState('')
  const [newDocument, setNewDocument] = useState(null)
  const [loadingFile, setLoadingFile] = useState(false)
  const [notes, setNotes] = useState('')

  useEffect(()=>{

  },[]);

  const onDocumentSelect = (e)=>{
    setNewDocumentName(e.target.files[0].name)
    setNewDocument(e.target.files[0])
  }

  const uploadDocument = async()=>{
    await setLoadingFile(true)

    const res = await store.dispatch(principalSlice.async$.createDocument({file:newDocument,notes}))

    console.log(res.data)

    setLoadingFile(false)
    setNewDocument(null)
    setNewDocumentName('')
    setNewDocumentWindowOpen(false)
  }

  const deleteDocument = async(id)=>{
    await setLoadingFile(id)

    const res = await store.dispatch(principalSlice.async$.deleteDocument({id}))

    console.log(res.data)

    setLoadingFile(false)
  }

  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.screen}>
          <ClientMenu activeScreen={'Documents'} />
          <div className={styles.content}>
            <Header name={user?.name} title={'My Uploaded Documents'} emojiName={'closed-book'} />
            <div className={styles.documentsContent}>
              <div className={styles.documentsSubtitleContainer}>
                <div className={styles.documentsSubtitle}>You can view your uploaded documents here. Your assigned accountant will have access to any documents you upload.</div>
                <div className={styles.newDocumentsButton} onClick={(e)=>{ setNewDocumentWindowOpen(true) }}>
                  Upload New <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                </div>
              </div>
              <div className={styles.documents}>
                <table className={styles.documentsTable}>
                    <tr className={styles.documentsTableHeader}>
                      <td className={styles.documentsTableHeaderItemLeft}>Document Name</td>
                      <td className={styles.documentsTableHeaderItem}>Uploaded At</td>
                      <td className={styles.documentsTableHeaderItemRight}>Delete</td>
                    </tr>
                    {
                      (documents || initialDocuments).map((document)=>{
                        //{title:'NIE-Application-Form.pdf', uploaded:1725758810}
                        return(
                          <tr className={styles.documentsTableRow}>
                            <td className={styles.documentsTableRowItem}>{document.title}</td>
                            <td className={styles.documentsTableRowItem}>{getDateTimeString(document.uploaded)}</td>
                            <td className={styles.documentsTableRowItem}>
                              {
                                (loadingFile && loadingFile === document.id)?
                                <ClipLoader size={50} color={'#5603fc'} />
                                :
                                <div className={styles.button} onClick={(e)=>{ deleteDocument(document.id) }}>
                                  Delete <Emoji name="prohibited" className={styles.emoji} width={16} />
                                </div>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                </table>
              </div>
            </div>
          </div>
          <PopupWindow title={<EmojiProvider data={emojiData}>New Document Upload <Emoji name="bookmark-tabs" className={styles.emoji} width={22} /></EmojiProvider>} onClose={()=>{ setNewDocumentWindowOpen(false) }} open={newDocumentWindowOpen}>
            <div className={styles.newDocumentContent}>
              <label for="logoInput">
                <div className={styles.fileButton} onClick={(e)=>{  }}>
                    Select File
                </div>
              </label>
              <br/>
              <input disabled={loadingFile} accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(e)=>{onDocumentSelect(e)}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
              <div className={styles.fileLabel}>
                {newDocumentName}
              </div>
              <br/>
              <TextInput placeholder={'Notes'} type={'email'} customStyle={{width:'320px',paddingTop:'10px',paddingBottom:'10px'}} onChange={(value)=>{setNotes(value)}} />
              <br/><br/>
              {
                (loadingFile)?
                <ClipLoader size={50} color={'#5603fc'} />
                :
                <div className={styles.documentsButton} onClick={(e)=>{ uploadDocument() }}>
                  Upload <Emoji name="cloud" className={styles.emoji} width={18} />
                </div>
              }
            </div>
          </PopupWindow>
      </div>
    </EmojiProvider>
  )
}