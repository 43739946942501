
import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import { PopupWindow } from '../../components/PopupWindow';
import styles from './styles.module.scss'
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ChatBot } from '../../components/ChatBot'
import { contentSectionOptions, contentItems } from '../../utils/contentSections.js'

export const Client = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const [content, setContent] = useState(null)
  const [contentTitle, setContentTitle] = useState(null)
  const [chosenSection, setChosenSection] = useState(contentSectionOptions[0])
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(()=>{
    setChosenSection(contentSectionOptions.filter((c)=> c.id === searchParams.get("topic"))[0]|| contentSectionOptions[0])
  },[]);

  const openContentItem = (item)=>{
    setContentTitle(item.title)
    setContent(item.content)
  }


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.screen}>
          <ClientMenu activeScreen={'Content'} />
          <div className={styles.content}>
            <Header name={user?.name} />
            <div className={styles.sections}>
              <div className={styles.sectionTitle}>Interactive Digital Nomad Guides & Planners</div>
              <div className={styles.sectionsList}>
                {
                  contentSectionOptions.map((section)=>{
                    return <div onClick={(e)=> setChosenSection(section)} className={(section.id === chosenSection.id)?styles.chosenSection:styles.section}><Emoji name={section.emoji} className={styles.emoji} width={22} />{section.title}</div>
                  })
                }
              </div>
              <br/>
              {
                contentItems.map((item,index)=>{
                  if(!(item.section === chosenSection.id || chosenSection.id === 'all')) return
                  return(
                    <div id={item.id} className={styles.contentItem} onClick={(e)=> openContentItem(item)}>
                      <img src={item.background} className={styles.contentItemImage} />
                      <div className={styles.contentItemFooter} style={{background:"linear-gradient(0deg,"+item.color+"FF,"+item.color+"00)"}}></div>
                      <div className={styles.contentItemTitle}>{item.title}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <PopupWindow windowStyles={{width:'60%'}} title={<EmojiProvider data={emojiData}>{contentTitle}<Emoji name="globe-showing-europe-africa" className={styles.emoji} width={22} /></EmojiProvider>} onClose={()=>{ setContent(null) }} open={content}>{content}</PopupWindow>
          <ChatBot />
      </div>
    </EmojiProvider>
  )
}