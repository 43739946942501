
import React,{ useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import styles from './styles.module.scss'
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ClipLoader } from "react-spinners";
import Select from "react-select"

import authSlice from '../../slices/authSlice'
import store from '../../store'

const Range = ({ref, key, question, onChange, value, placeholder, currency})=>{
  return(
    <div className="radio">
        <div className={styles.question}>{question}</div>
        <div className="radioOptions">
          <div className="rangeCurrency">{currency}</div>
          <input key={key} ref={ref} id={key} type="number" className="rangeInput" placeholder={placeholder} value={value} onChange={(event)=>{ onChange(event.target.value) }} />
        </div>
    </div>
  )
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className={styles.question}>{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}


const Radio = ({question, options, onChange, selected})=>{
  return(
    <div className="radio">
        <div className={styles.question}>{question}</div>
        <div className="radioOptions">
          {
            options.map((option)=>{
              return(
                (option === selected)?
                <div className="radioOptionSelected">{option}</div>
                :
                <div className="radioOption" onClick={(e)=>{ onChange(option) }}>{option}</div>
              )
            })
          }
        </div>
    </div>
  )
}

export const Client = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const userName = useSelector(state => state.authSlice?.userName) 
  const workType = useSelector(state => state.authSlice?.workType)
  const country = useSelector(state => state.authSlice?.country)
  const employerCountry = useSelector(state => state.authSlice?.employerCountry)
  const accountant = useSelector(state => state.authSlice?.accountant)
  const incomeRef = useRef(null)

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
        console.log(data.countries)
      })
  }, [])

  const updateProfile = async()=>{
    await setLoading(true)

    const res = await store.dispatch(authSlice.async$.updateProfile({userName, estimatedIncome,workType,country,employerCountry}))

    setLoading(false)
  }
  


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.screen}>
          <ClientMenu activeScreen={'Settings'} />
          <div className={styles.content}>
            <Header name={user?.name} title={'My Settings'} emojiName={'gear'} />
            <div className={styles.settingsContent}>
              <div className={styles.settingsForm}>
                <Radio question={'How Would You Describe Your Work?'} options={['I Am A Freelancer','I Own A Company','I Am An Employee']} selected={workType} onChange={(value)=>{ store.dispatch(authSlice.actions.setWorkType(value)) }} />
                  <div className={styles.question}>What Is Your Name?</div>
                  <div className={styles.submit}>
                    <input type="text" style={{marginRight:'0px'}} className="rangeInput" placeholder={'Full Name'} value={userName} onChange={(event)=>{ store.dispatch(authSlice.actions.setUserName(event.target.value)) }} />
                  </div>
                  {(workType === 'I Am A Freelancer')?<></>:<CountrySelect isMulti={false} question={'Where Is Your Employer / Company Located?'} countries={countries} value={countries.filter((country)=>{ return country.value === employerCountry})[0]} onChange={(option)=>{store.dispatch(authSlice.actions.setEmployerCountry(option.value))}} />}
                  
                 <Range ref={incomeRef} key={'dash-income-input'} currency={'€'} question={'What Is Your Gross Annual Income?'} value={estimatedIncome} onChange={async(value)=>{ 
                    await store.dispatch(authSlice.actions.setEstimatedIncome(value)) 
                   
                    }} />
                  <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country.map((c)=>{
                    return countries.filter((option)=>{ return option.value === c })[0]
                  })} onChange={(option)=>{ store.dispatch(authSlice.actions.setCountry(option.map((o)=>{ return o.value }))) }} />
                  <br/><br/>
                  <div className={styles.submit}>
                    {(loading)?
                    <ClipLoader size={50} color={'#5603fc'} />
                    :
                    <div className={styles.saveButton} onClick={(e)=>{ updateProfile() }}>
                    Save <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                    </div>
                    }
                  </div>
              </div>
              <div className={styles.settingsImage}></div>
            </div>
          </div>
      </div>
    </EmojiProvider>
  )
}