//Handle any non-200 HTTP responses from axios
const handleError = (error)=>{
    console.log(error)
    if (error.response && error.response.data?.error) {
      return {error:`Error: `+error.response.data.error}
    }else{
      return {error:'Error: Something went wrong. \n \n Please check your internet connection and try reloading.'}
    }
  }
  
  export default handleError