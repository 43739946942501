import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { PopupWindow } from '../../components/PopupWindow';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import store from '../../store'
import authSlice from '../../slices/authSlice'
import Select from "react-select"
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ClipLoader } from "react-spinners"

const REQUIREMENTS = ['I Need my NIE / TIE','I Need To Register For Tax']
const Range = ({ref, key, question, onChange, value, placeholder, currency})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          <div className="rangeCurrency">{currency}</div>
          <input key={key} ref={ref} id={key} type="number" className="rangeInput" placeholder={placeholder} value={value} onChange={(event)=>{ onChange(event.target.value) }} />
        </div>
    </div>
  )
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}


const Radio = ({question, options, onChange, selected})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          {
            options.map((option)=>{
              return(
                (option === selected)?
                <div className="radioOptionSelected">{option}</div>
                :
                <div className="radioOption" onClick={(e)=>{ onChange(option) }}>{option}</div>
              )
            })
          }
        </div>
    </div>
  )
}


export const Signup = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)
  const error = useSelector(state => state.authSlice?.error)
  const [loading,setLoading] = useState(false)
  const [signedUp,setSignedUp] = useState(false)
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const inSpain = useSelector(state => state.authSlice?.inSpain)
  const workType = useSelector(state => state.authSlice?.workType)
  const country = useSelector(state => state.authSlice?.country)
  const requirement = useSelector(state => state.authSlice?.requirement)
  const employerCountry = useSelector(state => state.authSlice?.employerCountry)
  const emailSent = useSelector(state => state.authSlice?.emailSent)
  const [countries, setCountries] = useState([])
  const [hasNie, setHasNie] = useState(false)
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    store.dispatch(authSlice.actions.setWorkType(searchParams.get('workType') || workType))
    store.dispatch(authSlice.actions.setCountry(searchParams.get('country')?.split(',') || country))
    store.dispatch(authSlice.actions.setEmployerCountry(searchParams.get('employerCountry') || employerCountry))
    store.dispatch(authSlice.actions.setEstimatedIncome(searchParams.get('estimatedIncome') || estimatedIncome))
    store.dispatch(authSlice.actions.setInSpain((searchParams.get('inSpain') == 1) || false))
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
        console.log(data.countries)
      })
  },[]);

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();
      authSlice.actions.setError('')
      const res = await store.dispatch(authSlice.async$.attemptSignup({email, password, name}))
      if(res.payload?.success){
        setSignedUp(true)
        console.log(res)
      }else{
        console.log(res)
        store.dispatch(authSlice.actions.setError('Something went wrong. Please try again.'))
      }
    },
    [email, password, name]
  )

  const updateProfile = useCallback(async(e)=>{

    if(requirement !== 'I Need My NIE / TIE' && !hasNie){
      await store.dispatch( authSlice.actions.setError('Please confirm that you have a NIE or TIE or select "I Need My NIE / TIE".'))
      console.log(error)
      return
    }

    const res = await store.dispatch(authSlice.async$.updateProfile({estimatedIncome,workType,country,employerCountry, requirement, inSpain}))

    console.log(res)

    if(res.payload?.success){
      document.location = './'
    }else{
      authSlice.actions.setError('Error. Please try again.')
    }
  },[estimatedIncome,workType,country,employerCountry, requirement, hasNie, error])



  if(signedUp){
    return (
      <EmojiProvider data={emojiData}>
      <div className="global-container">
          <div className={styles.loginLogoContainer}>
            <img src={require('../../images/logo.png')} className={styles.loginLogo} />
            <div className={styles.loginLogoText}>SimplyNomad</div>
          </div>
          <div id={styles.loginPanel}>
            <div id={styles.loginHeaderMain}>Let Us Know About You</div>
            <Radio question={'How Would You Describe Your Work?'} options={['I Am A Freelancer','I Own A Company','I Am An Employee']} selected={workType} onChange={(value)=>{ store.dispatch(authSlice.actions.setWorkType(value)) }} />
                  {(workType === 'I Am A Freelancer')?<></>:<CountrySelect isMulti={false} question={'Where Is Your Employer / Company Located?'} countries={countries} value={countries.filter((country)=>{ return country.value === employerCountry})[0]} onChange={(option)=>{store.dispatch(authSlice.actions.setEmployerCountry(option.value))}} />}
                  <Range key={'dash-income-input'} currency={'€'} question={'What Is Your Gross Annual Income?'} value={estimatedIncome} onChange={async(value)=>{ 
                    await store.dispatch(authSlice.actions.setEstimatedIncome(value)) 
                   
                    }} />
            <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country?.map((c)=>{
              return countries.filter((option)=>{ return option.value === c })[0]
            })} onChange={(option)=>{ store.dispatch(authSlice.actions.setCountry(option.map((o)=>{ return o.value }))) }} />
            <br/><br/>
            <div className={styles.note}>
            Note: A NIE/ TIE number is needed before registering for taxes in Spain.
            </div>
            <Radio question={'What Do You Need First?'} options={['I Need my NIE / TIE','I Need To Register For Taxes']} selected={requirement} onChange={(value)=>{ store.dispatch(authSlice.actions.setRequirement(value)) }} />
            <br/>
            <br/>
              <div className={styles.checkboxText}>
                <input type={'checkbox'} className={styles.checkbox} checked={inSpain} onChange={(e)=>{store.dispatch(authSlice.actions.changeInSpain()) }} /> I am currently in Spain or will be shortly.
              </div>
            <br/>
            {
              (requirement === 'I Need My NIE / TIE')?
              <></>
              :
              <div className={styles.checkboxText}>
                <input type={'checkbox'} className={styles.checkbox} checked={hasNie} onChange={(e)=>{ setHasNie(!hasNie) }} /> I can confirm I have a NIE or TIE, which is required before registering for taxes.
              </div>
            }
              
            <br/>
            {(error)?<div className={styles.loginError}>{error}</div>:<></>}
            <br/>
            <div className={styles.submit}>
              {(loading)?
              <ClipLoader size={50} color={'#5603fc'} />
              :
              <SubmitButton loading={loading} title={'Save Details'} emoji={<Emoji name="rocket" className="emoji" width={22} />} onClick={updateProfile} />
              }
            </div>


          <br/><br/>  
          <div className={styles.loginError}></div>
          </div>
      </div>
      <PopupWindow title={'Confirm Your Email'}>
  
      </PopupWindow>
      </EmojiProvider>
    )
  }




  return (
    <EmojiProvider data={emojiData}>
    <div className="global-container">
        <div className={styles.loginLogoContainer}>
          <img src={require('../../images/logo.png')} className={styles.loginLogo} />
          <div className={styles.loginLogoText}>SimplyNomad</div>
        </div>
        <div id={styles.loginPanel}>
          <div id={styles.loginHeaderMain}>Register For Spanish Taxes Now!</div>
          <br/>
          <TextInput placeholder={'Full Name'} type={'onboarding-input'} onChange={(e)=>{setName(e.target.value)}} />
          <TextInput placeholder={'Email'} type={'email'} onChange={(value)=>{setEmail(value)}} />
          <TextInput placeholder={'New Password'} type={'password'} onChange={(value)=>{setPassword(value)}} />
          <SubmitButton loading={loading} title={'Sign Up'} emoji={<Emoji name="rocket" className="emoji" width={22} />} onClick={onSubmit} />
          <br/>
          <div className={styles.loginError}>{error}</div>
          <br/>
          <div id={styles.loginLinks}>
            <a className={styles.loginLink} href="./login">Login</a>
          </div>
        </div>

        <div className={styles.loginCopyright}>
        </div>
    </div>
    <PopupWindow title={'Confirm Your Email'}>

    </PopupWindow>
    </EmojiProvider>
  )
}