import React,{useState} from 'react';

// Styles
import submitStyles from './index.module.scss';



export const SubmitButton = ({title,onClick, emoji})=>{

  return(
    <div className={submitStyles.formSubmitButton} onClick={onClick}>
      {title}
      <div className={submitStyles.emoji}>{(emoji)?(emoji):('')}</div>
    </div>
    
  )

}
