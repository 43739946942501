import React, { useEffect } /* , { useState } */ from 'react'
import { Routes, Route,  Navigate, useLocation, Outlet } from 'react-router-dom'
import { useSelector, Provider } from 'react-redux'
import authSlice from './slices/authSlice'

import store from './store'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import {Loading} from './views/Loading'
import {Login} from './views/Login'
import {AccountantLogin} from './views/AccountantLogin'
import {Dashboard} from './views/Dashboard'
import {Documents} from './views/Documents'
import {Invoices} from './views/Invoices'
import {Settings} from './views/Settings'
import {Messages} from './views/Messages'
import {Content} from './views/Content'
import {Signup} from './views/Signup'

function RequireAuth() {
  const status = useSelector(state => state.authSlice?.status)
  let location = useLocation()

  if ('public' === status) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  else if ('initial' === status){
    return <Loading />
  }

  return <Outlet />
}

function Public() {
  const status = useSelector(state => state.authSlice?.status)
  let location = useLocation()

  if ('public' === status) {
    return <Outlet />
  }
  else if ('initial' === status) {
    return <Loading />
  }

  return <Navigate to="/" state={{ from: location }} replace />
}

export const App = () => {

  useEffect(()=>{
    getProfile()
  },[])

  const getProfile = async()=>{
    const res = await store.dispatch(authSlice.async$.loadProfile())
    console.log(res)
  }

  return (
      <Provider store={store}>
        <RecoilRoot>
          <BrowserRouter>
              <Routes>
                <Route exact path='/login' element={<Public/>}>
                  <Route exact path="/login" element={<Login />} />
                </Route>
                <Route exact path='/signup' element={<Public/>}>
                  <Route exact path="/signup" element={<Signup />} />
                </Route>
                <Route exact path='/accountant-login' element={<Public/>}>
                  <Route exact path="/accountant-login" element={<AccountantLogin />} />
                </Route>
                <Route  path='/' element={<RequireAuth/>}>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route exact path="/documents" element={<Documents />} />
                  <Route exact path="/invoices" element={<Invoices />} />
                  <Route exact path="/settings" element={<Settings />} />
                  <Route exact path="/messages" element={<Messages />} />
                  <Route exact path="/content" element={<Content />} />
                </Route>
              </Routes>
          </BrowserRouter>
        </RecoilRoot>
      </Provider>
  )
}


export default App;
