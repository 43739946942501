import React, {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

// Styles
import styles from './index.module.scss';

// Assets
import defaultProfilePicture from '../../images/user.png'

export const MyAccountantTile = ({openChatbot})=>{
  const accountant = useSelector(state => state.authSlice?.accountant)
  const lawyer = useSelector(state => state.authSlice?.lawyer)
  //const accountant = {name:'Miguel Hernandez'}
  const user = useSelector(state => state.authSlice?.user)
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.tile}>
          {
          (accountant)?
            <div className={styles.content}>
              <div className={styles.profile}>
                <img src={accountant?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                <div className={styles.profileText}>
                  <div className={styles.title}>My Assigned Accountant</div>
                  <div className={styles.name}>{accountant?.name}</div>
                </div>
              </div>
              <br/><br/>
              <div className={styles.profile}>
                <img src={lawyer?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                <div className={styles.profileText}>
                  <div className={styles.title}>My Assigned Lawyer</div>
                  <div className={styles.name}>{lawyer?.name}</div>
                </div>
              </div>

              <div onClick={(e)=>{ document.location = './messages' }} className={styles.button}>
                Ask A Query <Emoji name="speech-balloon" className={styles.emoji} width={18} />
              </div>


              <div onClick={(e)=>{
                openChatbot(true)
               }} className={styles.chatbotButton}>
                Get Quick Answers <Emoji name="robot" className={styles.emoji} width={18} />
              </div>
            </div>
            :
            <div className={styles.content}>
              <div className={styles.noAccountantTitle}>No Assigned Accountant Yet!</div>
              <div className={styles.noAccountantSubtitle}>We will assign you one within 24 hours. Check below to see what you need to do next.</div>
              <div className={styles.button}>
                What Do I Need? <Emoji name="clapper-board" className={styles.emoji} width={18} />
              </div>
            </div>
          }
       </div>
    </EmojiProvider>
   
  )


}
