import React,{useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Client } from './client';
import { Accountant } from './accountant';

import styles from './styles.module.scss'

export const Invoices = () => {
  const type = useSelector(state => state.authSlice?.type)
  const navigate = useNavigate();

  useEffect(()=>{
    
  },[]);



  return (
    <div className={styles.screen}>
        {
          (type === 'client')?
          <Client/>
          :
          <Accountant/>
        }
    </div>
  )
}