import digitalNomad2 from '../images/digital-nomad-2.jpg'
import bankMeeting from '../images/bank-meeting.jpg'
import businessMeeting from '../images/business-meeting.jpg'
import townHall from '../images/town-hall.jpg'
import styles from '../App.css'

const EU_COUNTRIES = {
    'AT': 'Austria',
    'BE': 'Belgium',
    'BG': 'Bulgaria',
    'HR': 'Croatia',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'EE': 'Estonia',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'GR': 'Greece',
    'HU': 'Hungary',
    'IE': 'Ireland',
    'IT': 'Italy',
    'LV': 'Latvia',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MT': 'Malta',
    'NL': 'Netherlands',
    'PL': 'Poland',
    'PT': 'Portugal',
    'RO': 'Romania',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'ES': 'Spain',
    'SE': 'Sweden'
  }


const getSuggestedDocuments = ({passportCountries, employerCountry, accountant, requirement})=>{
    const EU_COUNTRY_VALUES = Object.keys(EU_COUNTRIES)
    const HAS_EU_PASSPORT = EU_COUNTRY_VALUES.map((EuCountry)=>{ return passportCountries.includes(EuCountry) }).includes(true)

    const SECTIONS = [
      {
        id:'NIE',
        to: 'lawyer',
        name:'NIE/ TIE Application', 
        information:(HAS_EU_PASSPORT)?
                <div className={'documentInformationContent'}>
                  <div className={'documentInformationText'}>
                  <div className={'documentInformationTitle'}>NIE Number</div>
                  The NIE is the foreign identifier number for EU citizens (not Spain) living in Spain.
                  Any EU citizen living in Spain for more than three months must apply for their NIE.<br/><br/> If 
                  you do not have a NIE currently, you can apply for one by filling out a form from your assigned accountant or by making an appointment 
                  at your nearest NIE station.<br/><br/>
                  More information can be found at <a href='https://www.interior.gob.es/opencms/es/servicios-al-ciudadano/tramites-y-gestiones/extranjeria/ciudadanos-de-la-union-europea/numero-de-Identidad-de-extranjero-nie/'>the official government website</a>.
                  </div>
                  <img src={digitalNomad2} className={'documentInformationImage'} />
                </div>
                :
                <div className={'documentInformationContent'}>
                  <div className={'documentInformationText'}>
                  <div className={'documentInformationTitle'}>TIE Number</div>
                  The TIE is the foreign identifier number for non-EU citizens (not Spain) living in Spain.
                  Any non-EU citizen living in Spain for more than three months must apply for their TIE.<br/><br/> If 
                  you do not have a TIE currently, you can apply for one by filling out a form from your assigned accountant or by making an appointment 
                  at your nearest TIE police station.<br/><br/>
                  More information can be found at <a href='https://www.inclusion.gob.es/web/migraciones/w/tarjeta-de-identidad-de-extranjero-tie-hi92-'>the official government website</a>.
                </div>
                <img src={digitalNomad2} className={'documentInformationImage'} />
                </div>
      },
      {id:'cert', name:'Digital Certificate', to: 'accountant', information:
        <div className={'documentInformationContent'}>
          <div className={'documentInformationText'}>
          <div className={'documentInformationTitle'}>Digital Certificate</div>
          The Digital Certificate is a virtual certificate that allows you to authenticate yourself for many government services in Spain, including paying taxes.<br/><br/>
          You can also apply for the Digital Certificate though your assigned accountant and a simple form! You must have your NIE or TIE number and passport available for this.<br/><br/>
          More information can be found at <a href='https://www.exteriores.gob.es/Consulados/toronto/en/ServiciosConsulares/Paginas/Consular/digital-certificate.aspx'>the official government website</a>.
        </div>
        <img src={businessMeeting} className={'documentInformationImage'} />
        </div>},
      /*{id:'empadronamiento',name:'Empadronamiento', to: 'accountant', information:
        <div className={'documentInformationContent'}>
          <div className={'documentInformationText'}>
          <div className={'documentInformationTitle'}>Empadronamiento</div>
          Once you move to Spain, you must register yourself at the nearest town hall or 'ayuntamiento'. This process is called empadronamiento. This confirms your address in Spain 
          for legal and administrative reasons. It adds you and your family to El Padrón - Spain's official population register. <br/><br/>
          Once again, our accountants can help you access the correct forms for your local ayuntamiento and help you submit them. <br/><br/>
          More information can be found at <a href='https://administracion.gob.es/pagFront/tramites/fichaTramite.htm?idTramiteSeleccionado=3485&idMateria=21&idBoletin=21'>the official government website</a>.
        </div>
        <img src={townHall} className={'documentInformationImage'} />
        </div>
        }*/
    ]

    if(true){
        return({sections:SECTIONS,documents:[
            {   
              name:'Passport',
              section:'NIE',
              id:'passport'
            },
            {   
              name:'Form 790, Code 12',
              section:'NIE',
              id:'790-12',
              download: 'https://sede.policia.gob.es/Tasa790_012/ImpresoRellenar'
            },
            {   
              name:(HAS_EU_PASSPORT)?'Ex-15 Form':'Ex-17 Form',
              section:'NIE',
              id:'Ex-Form',
              download:(HAS_EU_PASSPORT)?'https://www.exteriores.gob.es/Embajadas/budapest/hu/ServiciosConsulares/PublishingImages/Paginas/K%C3%BClf%C3%B6ldi-%C3%A1llampolg%C3%A1rok-Azonos%C3%ADt%C3%B3-Sz%C3%A1ma-NIE/EX-15%20%28English%20instructions%20for%20completing%20the%20form%29.pdf':'https://www.inclusion.gob.es/documents/410169/2156469/17-Formulario_TIE.pdf'
            },
            {
              name:'Apply for an appointment on the government website. Warning: We recommend against buying NIE appointments online, as they can be sourced illegally. Note: You must be in Spain or use a VPN to access this site. You may need to search for an appointment in different locations as they are difficult to find.',
              buttonTitle:'Make An Appointment',
              buttonLink:'https://icp.administracionelectronica.gob.es/icpplus/index.html',
              type:'button',
              section:'NIE'
            },
            {   
              name:(HAS_EU_PASSPORT)?'NIE Number':'TIE Number',
              section:(requirement === 'I Need my NIE / TIE')?'cert':'',
              id:'nie'
            },
        ]})
    }

    return []
}

export default getSuggestedDocuments