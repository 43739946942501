import React, { useState } from 'react';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { communities } from '../utils/contentSections.js'
import { TextInput } from '../components/TextInput'
const Content = () => {

  const [region, setRegion] = useState(communities[0].name)

  console.log(communities[0].name)


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>Tours & Guides In Each Spanish Region</div>
        <div className={styles.paragraph}>
        Spain, a vibrant tapestry of history, culture, and stunning landscapes, beckons travelers from around the globe to explore its rich heritage and diverse regions. <br/>
        From the sun-soaked beaches of the Costa del Sol to the artistic streets of Barcelona, and the historic alcázars of Seville, each destination offers a unique glimpse into the country's soul. <br/>
        Whether you're a history enthusiast, a foodie eager to savor tapas, or an adventurer seeking breathtaking hikes in the Pyrenees, Spain’s myriad tours provide unforgettable experiences tailored to every type of traveler. 
        </div>
        Your Autonomous Region:<br/>
        <TextInput type={'dropdown-mandatory'} options={communities.map((c)=> c.name)} value={region} onChange={(value)=>{ setRegion(value) }}></TextInput>
        <br/><br/>
            {
                communities.filter((c)=>{ return c.name === region })[0].tours.map((c)=>{
                    return(
                    <div className={styles.activity}>
                      <b>{c.title}:</b> 
                      <div className={styles.activityTime}>
                        {c.details.duration}
                      </div>

                      <div className={styles.activityDescription}>
                        {c.details.description}
                      </div>
                      <div className={styles.activityHighlights}>
                        {c.details.highlights.map((h)=>{
                          return(
                            <div className={styles.highlight}>
                              {h}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    )
                })
            }
      </div>
    </EmojiProvider>
  )
}

export default Content
