import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { communities } from '../utils/contentSections.js'
import { TextInput } from '../components/TextInput'

const Content = () => {

  const [region, setRegion] = useState(communities[0].name)


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>Winter Activities In Each Spanish Region</div>
        <div className={styles.paragraph}>
        If you’re moving to Spain, there are plenty of activities to enjoy in each region in the Winter months. Choose your region or one you plan 
        to visit below for suggestions.
        </div>
        Your Autonomous Region:<br/>
        <TextInput type={'dropdown-mandatory'} options={communities.map((c)=> c.name)} value={region} onChange={(value)=>{ setRegion(value) }}></TextInput>
        <br/><br/>
        {
                communities.filter((c)=>{ return c.name === region })[0].winter.map((c)=>{
                    return(
                    <div className={styles.activity}><b>{c.title}:</b> {c.details}</div>
                    )
                })
            }


      </div>
    </EmojiProvider>
  )
}

export default Content
