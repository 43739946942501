import React,{ useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AccountantMenu } from '../../components/AccountantMenu'
import { Header } from '../../components/Header'
import styles from './styles.module.scss'
import filterClients from '../../utils/filterClients'
import defaultProfilePicture from '../../images/user.png'
import { TextInput } from '../../components/TextInput'
import authSlice from '../../slices/authSlice'
import store from '../../store'
import { PopupWindow } from '../../components/PopupWindow'
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import getDateTimeString from '../../utils/getDateTimeString'
import isThisMonth from '../../utils/isThisMonth'

export const Accountant = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const clients = useSelector(state => state.authSlice?.clients)
  const clientInvoicesOpen = useSelector(state => state.authSlice?.clientInvoicesOpen)
  const invoices = useSelector(state => state.authSlice?.clientDocuments)
  const [search, setSearch] = useState('')
  const [clientName, setClientName] = useState('')

  useEffect(()=>{
    
  },[]);

  const getClientInvoices = async(client)=>{
    const res = await store.dispatch(authSlice.actions.setClientInvoices(client['sn/invoices']))
    setClientName(client.name)
  }

  const closeInvoicesWindow = ()=>{
    store.dispatch(authSlice.actions.closeInvoicesWindow())
  }

  return (
    <EmojiProvider data={emojiData}>
    <div className={styles.screen}>
      <AccountantMenu activeScreen={'Dashboard'} />
      <div className={styles.content}>
        <Header emojiName={'page-with-curl'} title={'Client Invoices'} />
        <div className={styles.clients}>
            <TextInput 
              customStyle={{
                width:'300px',
                marginLeft:'20px',
                padding:'10px',
                border:'2px solid #446BF7'
              }}
              placeholder={'Search Clients'} 
              type={'email'} 
              onChange={(value)=>{setSearch(value)}} />
            <br/><br/>
            {
              filterClients(search, clients).map((client)=>{
                return (
                  <div className={styles.client} onClick={(e)=>{ getClientInvoices(client) }}>
                    <img src={client?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                    <div className={styles.name}>{client.name}</div>
                  </div>
                )
              })
            }
        </div>
      </div>
      <PopupWindow windowStyles={{width:'60%',height:'70%'}} title={'Client Invoices: '+clientName} onClose={()=>{ closeInvoicesWindow() }} open={clientInvoicesOpen}>
          <div className={styles.documentsContent}>
                  <table className={styles.invoicesTable}>
                    <tr className={styles.invoicesTableHeader}>
                      <td className={styles.invoicesTableHeaderItemLeft}>Invoice Name</td>
                      <td className={styles.invoicesTableHeaderItemRight}>Uploaded At</td>
                    </tr>
                    {
                      (invoices || []).sort((a,b)=>{
                        return (b.uploaded - a.uploaded)
                      }).map((invoice)=>{
                        //{title:'NIE-Application-Form.pdf', uploaded:1725758810}
                        return(
                          <tr onClick={(e)=>{ window.open(invoice.url) }} className={{...styles.invoicesTableRow,background:isThisMonth(invoice.uploaded)?'#dbffea':'#ffffff'}}>
                            <td className={styles.invoicesTableRowItem}>{invoice.title}</td>
                            <td className={styles.invoicesTableRowItem}>{getDateTimeString(invoice.uploaded)}</td>
                          </tr>
                        )
                      })
                    }
                </table>
          </div>
      </PopupWindow>
    </div>
    </EmojiProvider>
  )
}