import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

const content = () => {


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>Making Friends In Spain</div>
        <div className={styles.paragraph}>
            Spain is renowned for its vibrant culture, warm climate, and, most importantly, its friendly people. Whether you're a traveler, an expat, or a student, forging friendships in Spain can enrich your experience and help you feel at home. Here are some tips to help you connect with locals and fellow internationals.
        </div>
        <div className={styles.subtitle}>1. Embrace the Language</div>
        <div className={styles.paragraph}>
            While many Spaniards speak English, making an effort to learn some Spanish can go a long way. <br/>
            Simple greetings and phrases can open doors and show your willingness to engage with the culture. <br/>
            Consider enrolling in a language class or using language exchange apps to practice.
        </div>

        <div className={styles.subtitle}>2. Join Social Activities</div>
        <div className={styles.paragraph}>
            Spain offers a plethora of social activities, from sports clubs to art classes. Joining local teams, dance classes, or cooking workshops not only helps you meet people with similar interests but also immerses you in Spanish culture. 
            <br/>Websites like Meetup or local Facebook groups can help you find events such as language exachanges in your area.
        </div>

        <div className={styles.subtitle}>3. Frequent Local Cafés and Bars</div>
        <div className={styles.paragraph}>
         Spanish culture revolves around socializing, often centered around cafés and tapas bars. <br/>
         Regular visits to your neighborhood spots can lead to casual conversations with locals and staff. <br/>
         Don’t hesitate to strike up a chat; Spaniards generally appreciate friendliness.
        </div>

        <div className={styles.subtitle}>4. Attend Festivals and Community Events</div>
        <div className={styles.paragraph}>
            Spain is famous for its vibrant festivals, such as La Tomatina, Fayas in Valencia or Feria de Abril. <br/>
            Attending these events is a fantastic way to meet people and experience the local culture. <br/>
            Community events often have a friendly atmosphere that encourages interaction.
        </div>

        <div className={styles.subtitle}>5. Be Open and Approachable</div>
        <div className={styles.paragraph}>
            Spaniards are known for their warmth and openness. <br/>
            A smile and a friendly demeanor can invite conversations. <br/>
            Don’t be afraid to start small talk; it’s a common practice. <br/>
            Ask about local recommendations or share your own experiences.
        </div>


        <div className={styles.paragraph}>
            Making friends in Spain can be a fulfilling journey filled with rich experiences. <br/>
            By embracing the language, engaging in community activities, and remaining open to new connections, you’ll not only expand your social circle but also deepen your appreciation for this beautiful country. <br/>
            So go out, be friendly, and enjoy all that Spain has to offer!
        </div>
      </div>
    </EmojiProvider>
  )
}

export default content
