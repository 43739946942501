import React, {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import store from '../../store'
import principalSlice from '../../slices/principalSlice'
import { PopupWindow } from '../PopupWindow'
import { ClipLoader } from "react-spinners"
import { TextInput } from '../TextInput'

// Styles
import styles from './index.module.scss'

// Assets
import defaultProfilePicture from '../../images/user.png'
import menuIcon from '../../images/menu.png'
import check from '../../images/check.png'

export const Header = ({name, profilePicture, title, emojiName})=>{
  const [supportOpen, setSupportOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const navigate = useNavigate();

  const openMenu = ()=>{
    store.dispatch(principalSlice.actions.updateMenuStatus())
  }

  const sendMessage = async()=>{
    await setLoading(true)
    await setError('')
    const res = await store.dispatch(principalSlice.async$.support({message}))
    await setLoading(false)
    if(res.payload?.success){
      await setMessageSent(true)
    }else{
      await setError('There was an issue sending your message. Please try again.')
    }
  }


  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.header}>
          <div className={styles.headerWelcome}>
            <Emoji name={emojiName || "waving-hand"} className={styles.emoji} width={28} /> {(title)?title:'Welcome Back, '+name}
          </div>
          <div className={styles.headerProfile}>
            <div className={styles.support} onClick={(e)=>{ setSupportOpen(true) }}>Technical Support</div>
            <a href="./settings">
              <img src={profilePicture || defaultProfilePicture} className={styles.headerProfileImage} />
            </a>
            <img onClick={(e)=>{ openMenu() }} src={menuIcon} className={styles.menuIcon} />
          </div>
        </div>
        <PopupWindow windowStyles={{width:'60%'}} title={<EmojiProvider data={emojiData}> Get Support <Emoji name="speech-balloon" className={styles.emoji} width={22} /></EmojiProvider>} onClose={()=>{ setSupportOpen(false) }} open={ supportOpen } >
        <div className={styles.center}>
                
                {(messageSent)? 
                <>
                  <img className={styles.check} src={check} />
                   <div className={styles.supportInfo}>
                      Your Message was sent. <br/>
                      We will try to respond to you within 24 hours!
                    </div>
                </>:
                    <>
                    <div className={styles.supportInfo}>
                      Please let us know how we can help. <br/>
                      If you have a legal or accounting query, please contact your accountant or lawyer. <br/>
                      We will try to respond to you within 24 hours!
                    </div>
                    <textarea onChange={(e)=>{setMessage(e.target.value)}} value={message} placeholder={'Please enter your general query. Note: For legal and accounting issues, contact your lawyer or accountant.'} className={styles.query} />
                    <br/>
                    <div className={styles.error}>{error}</div>
                    <br/>
                    {
                      (loading)?
                      <ClipLoader size={50} color={'#5603fc'} />
                      :
                      <div className={styles.button} onClick={(e)=>{ sendMessage() }}>
                        Send
                      </div>
                    }
                  </>
              }
        </div>
        </PopupWindow>
    </EmojiProvider>
   
  )


}
