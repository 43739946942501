import React,{ useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AccountantMenu } from '../../components/AccountantMenu'
import { Header } from '../../components/Header'
import styles from './styles.module.scss'
import filterClients from '../../utils/filterClients'
import defaultProfilePicture from '../../images/user.png'
import { TextInput } from '../../components/TextInput'
import authSlice from '../../slices/authSlice'
import store from '../../store'
import { PopupWindow } from '../../components/PopupWindow'
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

export const Accountant = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const clients = useSelector(state => state.authSlice?.clients)
  const clientDocumentsOpen = useSelector(state => state.authSlice?.clientDocumentsOpen)
  const documents = useSelector(state => state.authSlice?.clientDocuments)
  const [search, setSearch] = useState('')
  const [clientName, setClientName] = useState('')

  useEffect(()=>{
    
  },[]);

  const getClientDocuments = async(client)=>{
    const res = await store.dispatch(authSlice.actions.setClientDocuments(client['sn/documents']))
    setClientName(client.name)
  }

  const closeDocumentsWindow = ()=>{
    store.dispatch(authSlice.actions.closeDocumentsWindow())
  }

  return (
    <EmojiProvider data={emojiData}>
    <div className={styles.screen}>
      <AccountantMenu activeScreen={'Dashboard'} />
      <div className={styles.content}>
        <Header emojiName={'page-with-curl'} title={'Client Documents'} />
        <div className={styles.clients}>
            <TextInput 
              customStyle={{
                width:'300px',
                marginLeft:'20px',
                padding:'10px',
                border:'2px solid #446BF7'
              }}
              placeholder={'Search Clients'} 
              type={'email'} 
              onChange={(value)=>{setSearch(value)}} />
            <br/><br/>
            {
              filterClients(search, clients).map((client)=>{
                return (
                  <div className={styles.client} onClick={(e)=>{ getClientDocuments(client) }}>
                    <img src={client?.profileImage || defaultProfilePicture} className={styles.profileImage} />
                    <div className={styles.name}>{client.name}</div>
                  </div>
                )
              })
            }
        </div>
      </div>
      <PopupWindow windowStyles={{width:'60%',height:'70%'}} title={'Client Documents: '+clientName} onClose={()=>{ closeDocumentsWindow() }} open={clientDocumentsOpen}>
          <div className={styles.documentsContent}>
            {
              (documents || []).map((document)=>{
                return(
                  <div className={styles.document} onClick={(e)=>{ window.open(document.url) }}>
                    <Emoji name='page-with-curl' className={styles.emoji} width={32} />
                    {document.title}
                  </div>
                )
              })
            }
          </div>
      </PopupWindow>
    </div>
    </EmojiProvider>
  )
}