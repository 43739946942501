import React,{ useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { AccountantMenu } from '../../components/AccountantMenu';
import { Header } from '../../components/Header';
import styles from './styles.module.scss'
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ClipLoader } from "react-spinners";
import Select from "react-select"

import authSlice from '../../slices/authSlice'
import store from '../../store'

export const Accountant = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const userName = useSelector(state => state.authSlice?.userName)
  const userDescription = useSelector(state => state.authSlice?.userDescription)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    
  }, [])

  const updateProfile = async()=>{
    await setLoading(true)

    const res = await store.dispatch(authSlice.async$.updateAccountantProfile({userName, userDescription}))

    console.log(res.data)

    setLoading(false)
  }
  
  
  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.screen}>
          <AccountantMenu activeScreen={'Settings'} />
          <div className={styles.content}>
            <Header name={user?.name} title={'My Settings'} emojiName={'gear'} />
            <div className={styles.settingsContent}>
              <div className={styles.settingsForm}>
                 <div className={styles.question}>What Is Your Name?</div>
                  <div className={styles.submit}>
                    <input type="text" style={{marginRight:'0px'}} className="rangeInput" placeholder={'Full Name'} value={userName} onChange={(event)=>{ store.dispatch(authSlice.actions.setUserName(event.target.value)) }} />
                  </div>
                  <div className={styles.question}>Your Bio</div>
                  <div className={styles.submit}>
                    <input type="text" style={{marginRight:'0px'}} className="rangeInput" placeholder={'Bio'} value={userDescription} onChange={(event)=>{ store.dispatch(authSlice.actions.setUserDescription(event.target.value)) }} />
                  </div>
                 <br/><br/>
                  <div className={styles.submit}>
                    {(loading)?
                    <ClipLoader size={50} color={'#5603fc'} />
                    :
                    <div className={styles.saveButton} onClick={(e)=>{ updateProfile() }}>
                    Save <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                    </div>
                    }
                  </div>
              </div>
              <div className={styles.settingsImage}></div>
            </div>
          </div>
      </div>
    </EmojiProvider>
  )
}