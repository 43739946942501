import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

const content = () => {


  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>Understanding Spanish Culture</div>
        <div className={styles.paragraph}>
        Living in Spain is a vibrant experience, marked by rich traditions, passionate people, and a unique way of life. <br/>
        Understanding Spanish culture can significantly enhance your experience and help you integrate more smoothly into your new environment. <br/>
        Here are some key aspects of Spanish culture to embrace while living in Spain.
        </div>
        <div className={styles.subtitle}>1. Siesta and Work-Life Balance</div>
        <div className={styles.paragraph}>
        The concept of the siesta—an afternoon break—reflects Spain’s emphasis on a balanced lifestyle. <br/>
        Many shops and businesses close for a few hours in the afternoon, allowing people to rest and recharge. <br/>
        Embrace this slower pace, and consider adopting a more relaxed attitude toward time, especially in social settings.
        </div>

        <div className={styles.subtitle}>2. Dining Etiquette and Meal Times</div>
        <div className={styles.paragraph}>
        Spanish meal times differ significantly from many other cultures. Lunch is typically served between 1:30 PM and 3:30 PM, while dinner often starts around 9 PM or later. <br/>
        Tapas, small dishes meant for sharing, are a staple of Spanish dining. <br/>
        Engaging in this communal eating style not only satisfies your taste buds but also fosters social connections.
        </div>

        <div className={styles.subtitle}>3. Emphasis on Family and Community</div>
        <div className={styles.paragraph}>
        Family is central to Spanish culture, often influencing social structures and relationships. <br/>
        Expect to see families gathering frequently, whether for meals or celebrations. <br/>
        Participating in community events and family-oriented gatherings can help you forge deeper connections with locals.
        </div>

        <div className={styles.subtitle}>4. Passion for Festivals and Celebrations</div>
        <div className={styles.paragraph}>
        Spain boasts a rich tapestry of festivals, each celebrating local traditions and customs. <br/>
        From Semana Santa (Holy Week) to La Tomatina and Las Fallas, these events are vibrant, lively, and often deeply rooted in history. <br/>
        Joining in on these festivities is a great way to immerse yourself in the culture and meet new people.
        </div>

        <div className={styles.subtitle}>5. Expressiveness and Communication Style</div>
        <div className={styles.paragraph}>
        Spaniards are known for their expressive communication style, often using gestures and a lively tone. <br/>
        It’s common for conversations to be animated and passionate. <br/>
        Don’t be surprised if conversations feel more personal; this is a reflection of the warmth and openness inherent in Spanish culture.
        </div>

        <div className={styles.subtitle}>6. Regional Diversity</div>
        <div className={styles.paragraph}>
        Spain is a country of regions, each with its own distinct traditions, languages, and cuisines. <br/>
        Understanding this diversity is crucial. For example, the Basque Country, Catalonia, and Andalusia all have unique customs and identities. <br/>
        Take the time to learn about the local culture wherever you are, as it will enrich your experience and help you connect with locals.
        </div>

        <div className={styles.subtitle}>7. Appreciation for Art and History</div>
        <div className={styles.paragraph}>
        Spain has a rich artistic heritage, from the works of Picasso and Dalí to the stunning architecture of Gaudí. <br/>
        Exploring museums, galleries, and historical sites can deepen your appreciation for the country’s cultural wealth. <br/>
        Participate in local art and music events to engage more fully with this aspect of Spanish life.
        </div>


        <div className={styles.paragraph}>
          Understanding Spanish culture is key to making the most of your time in Spain. <br/>
          By embracing the local customs, social dynamics, and regional differences, you’ll not only enhance your own experience but also foster meaningful connections with the people around you. <br/>
          Enjoy the journey of cultural discovery in this beautiful country!
       </div>
      </div>
    </EmojiProvider>
  )
}

export default content
