import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

const content = () => {

  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>Applying For Your NIE In Spain</div>
        <div className={styles.paragraph}>
        If you’re moving to Spain, one of the first steps you need to take is obtaining your NIE (Número de Identificación de Extranjero). <br/>
        This identification number is essential for various administrative processes, including opening a bank account, signing a lease, and working legally. <br/>
        Here’s a straightforward guide on how to secure your NIE. Your accountant / lawyer on Simply Nomad can help guide you and prepare your documents.
        </div>
        <br/><br/>
        <iframe style={{alignSelf:'center'}} width="650" height="315" 
        src="https://www.youtube.com/embed/IYlH0iizIb0?si=WhiIbtM82bXt9-rj" 
        title="NIE Application" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
        <br/><br/>
        <div className={styles.subtitle}>1. Understand the Purpose of the NIE</div>
        <div className={styles.paragraph}>
        The NIE serves as a tax identification number for foreigners in Spain. <br/>
        It is required for almost any legal activity, such as buying property, setting up utilities, or conducting business. <br/>
        You will need this to be employed or to register as an Autonomo in Spain.
        </div>

        <div className={styles.subtitle}>2. Prepare Your Documents</div>
        <div className={styles.paragraph}>
        Before applying, gather the necessary documents:
        <br/>
        <list>
          <li>Valid Passport: A copy of your passport (and sometimes the original).</li>
          <li>Completed Application Form: Fill out the EX-15 form, which can usually be downloaded from the official website or obtained at the office.</li>
          <li>Proof of Residency or Purpose: A document demonstrating your reason for needing the NIE (e.g., employment contract, student enrollment, or proof of property purchase).</li>
          <li>Passport-sized Photos: Depending on the office, you may need to provide recent passport-sized photographs.</li>
        </list>
        </div>

        <div className={styles.subtitle}>3. Book an Appointment</div>
        <div className={styles.paragraph}>
        To streamline the process, it's advisable to book an appointment (cita previa) online through the Spanish police or immigration website. <br/>
        Appointments can fill up quickly, so try to book as soon as you arrive in Spain. <br/>
        More information can be found at <a href='https://www.interior.gob.es/opencms/es/servicios-al-ciudadano/tramites-y-gestiones/extranjeria/ciudadanos-de-la-union-europea/numero-de-Identidad-de-extranjero-nie/'>the official government website</a>.
        </div>

        <div className={styles.subtitle}>4. Pay The Fee</div>
        <div className={styles.paragraph}>
        There is usually a small fee for obtaining your NIE, which varies depending on the province. <br/>
        Payment can often be made at the bank, where you’ll receive a payment form to fill out. The 790-012 form is used to pay the fees associated with various administrative procedures, including your NIE application. <br/>
        It must be completed, printed, and attached to the EX-15 form.
        </div>

        <div className={styles.subtitle}>5. Visit the Designated Office</div>
        <div className={styles.paragraph}>
        On the day of your appointment, go to the designated police station or immigration office. <br/>
        This address will be given to you when you make your appointment and varies by region. <br/>
        Bring all your documents and be prepared for a potential wait. Be punctual, as lateness can result in rescheduling.
        </div>

        <div className={styles.subtitle}>6. Receive Your NIE</div>
        <div className={styles.paragraph}>
        Once your application is processed, you will receive your NIE number. <br/>
        This can take a few minutes to a few days, depending on the office. <br/>
        Make sure to keep the document safe, as you’ll need it for various transactions in Spain.
        </div>

        <div className={styles.paragraph}>
        Obtaining your NIE number is a crucial step when moving to Spain. By preparing your documents, booking an appointment, and following the process, you can ensure a smooth experience. <br/>
        Once you have your NIE, you’ll be well-equipped to navigate your new life in Spain!
        </div>
      </div>
    </EmojiProvider>
  )
}

export default content
