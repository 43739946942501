
export const getLatestMessage = (messages, client)=>{
    let clientMessages = messages.filter((message)=>{ return (message.clientId) === client.id }).sort((a,b)=>{ return a.sent - b.sent })
    let lastMessage = clientMessages[clientMessages.length - 1]

    return lastMessage
}

export const getLatestMessageClient = (messages, client)=>{
    let clientMessages = messages.filter((message)=>{ return (message.accountantId) === client.id }).sort((a,b)=>{ return a.sent - b.sent })
    let lastMessage = clientMessages[clientMessages.length - 1]

    return lastMessage
}

export const getLastMessageShortened = (messages, client)=>{
    if(!client) return
    let lastMessage = getLatestMessage(messages, client)


    return (lastMessage?.text.length > 20)?((lastMessage?.text.substring(0, 20))+'...'):(lastMessage?.text)
}

export const getLastMessageShortenedClient = (messages, client)=>{
    if(!client) return

    let lastMessage = getLatestMessageClient(messages, client)

    console.log(lastMessage)
    console.log(messages)

    return (lastMessage?.text.length > 20)?((lastMessage?.text.substring(0, 20))+'...'):(lastMessage?.text)
}

export const orderClientsByMessages = (messages, clients)=>{
    return [...clients].sort((a, b)=>{
        return (getLatestMessage(messages, b)?.sent - getLatestMessage(messages, a)?.sent) || 0
    })
}

export const firstToUpper = (string)=>{
    return (string?.charAt(0).toUpperCase() + string?.slice(1)) || '';
}

export const lastMessageSeenClient = (messages, client)=>{
    let lastMessage = getLatestMessageClient(messages, client)

    console.log('Sent')
    console.log(lastMessage)
    console.log(messages)
    return lastMessage?.senderId == 1 && lastMessage?.opened === 1
    
}

export const lastMessageSeenAccountant = (messages, client)=>{
    let lastMessage = getLatestMessage(messages, client)
    return lastMessage?.senderId == 0 && lastMessage.opened === 1
}