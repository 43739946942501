import digitalNomad1 from '../images/digital-nomad-1.jpg'
import globalStyles from '../App.css'

const EU_COUNTRIES = {
    'AT': 'Austria',
    'BE': 'Belgium',
    'BG': 'Bulgaria',
    'HR': 'Croatia',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'EE': 'Estonia',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'GR': 'Greece',
    'HU': 'Hungary',
    'IE': 'Ireland',
    'IT': 'Italy',
    'LV': 'Latvia',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MT': 'Malta',
    'NL': 'Netherlands',
    'PL': 'Poland',
    'PT': 'Portugal',
    'RO': 'Romania',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'ES': 'Spain',
    'SE': 'Sweden',
    'IS':'Iceland', 
    'LI':'Liechtenstein',
    'NO':'Norway'
  }

const nomadVisaAvailable = ({passportCountries, employerCountry, estimatedIncome})=>{
    const EU_COUNTRY_VALUES = Object.keys(EU_COUNTRIES)
    const HAS_EU_PASSPORT = EU_COUNTRY_VALUES.map((EuCountry)=>{ return passportCountries.includes(EuCountry) }).includes(true)

    if(HAS_EU_PASSPORT) return false 
    if(employerCountry === 'Spain') return false 
    if(estimatedIncome && estimatedIncome < 27115.20) return false
    return true
}

const nomadVisaContent = ()=>{
          return(
          <div className={'documentInformationContent'}>
            <div className={'documentInformationText'}>
              <div className={'documentInformationTitle'}>Spanish Digital Nomad Visa</div>
              The Spanish Digital Nomad Visa was launched in January 2023. It is a special visa available to non-EU citizens.
              <br/><br/>
              You must have a degree or at least 3 years of experience in your acitvity. You may work for a Spanish company, but it may only represent less than 20% of your income.
              <br/><br/>
              The Digital Nomad Visa or "Beckham's Law" allows you to pay a flat tax rate of 24% in Spanish-sourced income for 6 years. Our accountants can provide you with detailed information for your case.
              <br/><br/>
              Standard documents to apply include:
              <list>
              <li>1) Copy of the complete valid passport.</li>
              <li>2) Proof of employment relationship or freelance contract.</li>
              <li>3) Accreditation of the real and continuous activity for at least 1 year of the foreign
company or group of companies with which he/she maintain a labor or professional
relationship.</li>
<li>4) Letter from the foreign company, authorizing the development of remote work from
Spain, accrediting the profile of the position, salary to be received, terms and other conditions
in which you are going to carry out the activity remotely (If it is a labor relationship).</li>
<li>5) Copy of the degree related to the performance of the position or, where
appropriate, a minimum of 3 years experience in functions similar to the position to be held.</li>
<li>6) Economic means (200% S.M.I.) = 2.160€/month</li>
<li>7) Private medical insurance.</li>
<li>8) Administrative fee.</li>
              </list>
              <br/><br/>
              More information can be found at <a href='https://www.exteriores.gob.es/Consulados/londres/en/ServiciosConsulares/Paginas/Consular/Digital-Nomad-Visa.aspx'>the official government website</a>.
            </div>
            <img src={digitalNomad1} className={'documentInformationImage'} />
          </div>
          )
}

const nomadVisaDocuments = [
  {   
    name:'Passport',
    id:'passport'
  },
  {   
    name:'Proof of employment relationship or freelance contract.',
    id:'employmentproof'
  },
  {   
    name:'Accreditation/ Proof of the real and continuous activity for at least 1 year of the foreign company or group of companies with which you maintain a labor or professional relationship.',
    id:'accrediation'
  },
  {   
    name:'Letter from the foreign company, authorizing the development of remote work from Spain, accrediting the profile of the position, salary to be received, terms and other conditions in which you are going to carry out the activity remotely (If it is a labor relationship).',
    id:'companyletter'
  },
  {   
    name:'Copy of the degree related to the performance of the position or, where appropriate, a minimum of 3 years experience in functions similar to the position to be held.',
    id:'degree'
  },
  {   
    name:'Proof of economic means (200% S.M.I.) = 2.160€/month',
    id:'economicmeans'
  },
  {   
    name:'Proof of private medical insurance.',
    id:'medicalinsurance'
  },
  {   
    name:'Form 790, Code 12. This is the payment of the government administrative fee for the submission of your application. This comes last in the process.',
    id:'790-12',
    download: 'https://sede.policia.gob.es/Tasa790_012/ImpresoRellenar'
  }
]

export {nomadVisaAvailable, nomadVisaContent, nomadVisaDocuments}
