import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { AccountantMenu } from '../../components/AccountantMenu';
import { Header } from '../../components/Header';
import styles from './styles.module.scss'

export const Accountant = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)

  useEffect(()=>{
    
  },[]);



  return (
    <div className={styles.screen}>
      <AccountantMenu activeScreen={'Dashboard'} />
      <div className={styles.content}>
        <Header name={user?.name} />
      </div>
    </div>
  )
}