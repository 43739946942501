
const filterClients = (search, clients)=>{
   if(!search?.length) return clients 

   return clients.filter((client)=>{
        let capsName = client.name?.toUpperCase()
        let capsSearch = search?.toUpperCase()
        return (capsName.includes(capsSearch) || capsSearch.includes(capsName))
   })
}

export default filterClients