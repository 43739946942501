import React, {useState, useEffect} from 'react'
import useWindowDimensions from '../../utils/window'
import { useSelector } from 'react-redux'
import Cookies from "universal-cookie"
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

// Styles
import styles from './index.module.scss'

// Assets

const cookies = new Cookies()

export const Intro = ({}) => {

  const user = useSelector(state => state.authSlice?.user)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const introComplete = cookies.get("introComplete");
    if(!introComplete){
      setOpen(true)
    }
  }, [])



  if(open){
    return(
      <EmojiProvider data={emojiData}>
        <div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.documentsWindowContainer}
      >
          <div className={styles.documentsWindow} >
            <div className={styles.documentsWindowTitle}>Welcome To Simply Nomad!</div>
            <div className={styles.documentsWindowContent}>
                  <Emoji name={"waving-hand"} className={styles.emoji} width={36} />
                  <div className={styles.nextStepTitle}>
                    Step 1
                  </div>
                  <div className={styles.nextStepSubtitle}>
                    The first step is to upload your documents to apply for a NIE / TIE or digital nomad visa.
                    <br/>
                    Then, you can submit it to our team for review, who may need to schedule a call with you.
                  </div>
                  <br/><br/>
                  <div className={styles.submit}>
                    <div className={styles.saveButton} onClick={(e)=>{ 
                      setOpen(false) 
                      cookies.set('introComplete', 1)
                    }}>
                    Let's Start
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </EmojiProvider>
    )
  }
  

}
