
import React,{ useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import { PopupWindow } from '../../components/PopupWindow';
import styles from './styles.module.scss'
import { EmojiProvider, Emoji } from "react-apple-emojis" 
import emojiData from "react-apple-emojis/src/data.json"
import getDateTimeString from '../../utils/getDateTimeString';
import isThisMonth from '../../utils/isThisMonth';
import { ClipLoader } from "react-spinners";
import {TextInput} from '../../components/TextInput/index.js';

import principalSlice from '../../slices/principalSlice'
import authSlice from '../../slices/authSlice'
import store from '../../store'
import { currencies, getCurrencySymbol } from '../../utils/currencies.js'
import jsPDF from 'jspdf'
import {PDF} from './pdf'

export const Client = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const invoices = useSelector(state => state.principalSlice?.invoices)
  const initialInvoices = useSelector(state => state.authSlice?.invoices)
  const [newInvoiceWindowOpen, setNewInvoiceWindowOpen] = useState(false)
  const [newInvoiceFileName, setNewInvoiceFileName] = useState('')
  const [newInvoiceName, setNewInvoiceName] = useState('')
  const [newInvoiceSentDate, setNewInvoiceSentDate] = useState(getDateTimeString(new Date().getTime()))
  const [newInvoiceDueDate, setNewInvoiceDueDate] = useState(getDateTimeString(new Date().getTime()))
  const [newInvoice, setNewInvoice] = useState(null)
  const [newInvoiceTax, setNewInvoiceTax] = useState(0)
  const [newInvoiceNotes, setNewInvoiceNotes] = useState('')
  const [newInvoiceTerms, setNewInvoiceTerms] = useState('')
  const [newInvoiceSenderVAT, setNewInvoiceSenderVAT] = useState('')
  const [newInvoiceReceiverVAT, setNewInvoiceReceiverVAT] = useState('')
  const [newInvoiceCurrency, setNewInvoiceCurrency] = useState(currencies[0].name)
  const invoiceFrom = useSelector(state => state.authSlice?.invoiceFrom)
  const [newInvoiceToAddress, setNewInvoiceToAddress] = useState('')
  const [loadingFile, setLoadingFile] = useState(false)
  const [saveFromAddress, setSaveFromAddress] = useState(true)
  const [invoiceEditorOpen, setInvoiceEditorOpen] = useState(false)
  const [invoiceItems, setInvoiceItems] = useState([])
  const [invoiceItemPrice, setInvoiceItemPrice] = useState('')
  const [invoiceItemName, setInvoiceItemName] = useState('')
  const [invoiceItemQuantity, setInvoiceItemQuantity] = useState('')
  const [pdfZIndex, setPdfZIndex] = useState(0)
  const [notes, setNotes] = useState('')
  const pdfRef = useRef(null)

  useEffect(()=>{

  },[]);

  const createInvoice = async()=>{
    //A4 Dims: 210 x 297

    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4'
    })

    doc.html(document.getElementById('pdf-download'), {
      async callback(doc) {
          console.log(doc.output())
          let file = doc.output('blob')
          const res = await store.dispatch(principalSlice.async$.createInvoice({fileName:newInvoiceName+'.pdf',file,notes:''}))
          await doc.save(newInvoiceName+'.pdf');
          setInvoiceEditorOpen(false)
          setInvoiceItems([])
      },
    })

  }

  const onInvoiceSelect = (e)=>{
    setNewInvoiceFileName(e.target.files[0].name)
    setNewInvoice(e.target.files[0])
  }

  const uploadInvoice = async()=>{
    await setLoadingFile(true)

    const res = await store.dispatch(principalSlice.async$.createInvoice({file:newInvoice,notes}))

    setLoadingFile(false)
    setNewInvoice(null)
    setNewInvoiceFileName('')
    setNewInvoiceWindowOpen(false)
  }

  const deleteInvoice = async(id)=>{
    await setLoadingFile(id)

    const res = await store.dispatch(principalSlice.async$.deleteInvoice({id}))

    setLoadingFile(false)
  }

  

  const addInvoiceItem = ()=>{
    if(invoiceItemName === '') return;
    if(invoiceItemQuantity === '') return;
    if(invoiceItemPrice === '') return;

    setInvoiceItems([...invoiceItems,{name:invoiceItemName,price:invoiceItemPrice,quantity:invoiceItemQuantity}])
    setInvoiceItemName('')
    setInvoiceItemPrice('')
    setInvoiceItemQuantity('')
  }

  const removeInvoiceItem = (index)=>{
    let tmp = invoiceItems 
    tmp.splice(index,1)
    setInvoiceItems([...tmp])
  }

  const getSubtotal = ()=>{
    let subtotal = 0

    for(let i = 0;i < invoiceItems.length;i++){
      subtotal += (invoiceItems[i].price * invoiceItems[i].quantity)
    }

    return (subtotal)
  }

  const getInvoiceTotal = ()=>{
    let subtotal = 0

    for(let i = 0;i < invoiceItems.length;i++){
      subtotal += (invoiceItems[i].price * invoiceItems[i].quantity)
    }

    return (subtotal + ((subtotal * newInvoiceTax)/100))
  }

  return (
    <EmojiProvider data={emojiData}>
          <PDF 
            ref={pdfRef} 
            zIndex={-1} 
            invoiceItems={invoiceItems} 
            from={invoiceFrom} 
            currency={newInvoiceCurrency} 
            to={newInvoiceToAddress} 
            dueDate={newInvoiceDueDate} 
            sentDate={newInvoiceSentDate} 
            tax={newInvoiceTax} 
            name={newInvoiceName} 
            notes={newInvoiceNotes} 
            terms={newInvoiceTerms} 
            senderVAT={newInvoiceSenderVAT}
            receiverVAT={newInvoiceReceiverVAT}
          />
      <div className={styles.screen}>
          <ClientMenu activeScreen={'Invoices'} />
          <div className={styles.content}>
            <Header name={user?.name} title={'My Invoices'} emojiName={'receipt'} />
            <div className={styles.invoicesContent}>
              <div className={styles.invoicesSubtitleContainer}>
                <div className={styles.invoicesSubtitle}>You can view your uploaded invoices here. Your assigned accountant will have access to any invoices you upload or create. Invoices in green were sent this month.</div>
                <div className={styles.newInvoicesButton} onClick={(e)=>{ setNewInvoiceWindowOpen(true) }}>
                  Upload <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                </div>
                <div className={styles.newInvoicesButton} onClick={(e)=>{ setInvoiceEditorOpen(true) }}>
                  Create New <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                </div>
              </div>
              <div className={styles.invoices}>
                <table className={styles.invoicesTable}>
                    <tr className={styles.invoicesTableHeader}>
                      <td className={styles.invoicesTableHeaderItemLeft}>Invoice Name</td>
                      <td className={styles.invoicesTableHeaderItem}>Uploaded At</td>
                      <td className={styles.invoicesTableHeaderItemRight}>Delete</td>
                    </tr>
                    {
                      (invoices || initialInvoices).map((invoice)=>{
                        //{title:'NIE-Application-Form.pdf', uploaded:1725758810}
                        return(
                          <tr className={styles.invoicesTableRow} style={{backgroundColor:isThisMonth(invoice.sentDate)?'#dbffea':'#ffffff'}}>
                            <td onClick={(e)=>{ window.open(invoice.url) }} className={styles.invoicesTableRowItem}>{invoice.title}</td>
                            <td onClick={(e)=>{ window.open(invoice.url) }} className={styles.invoicesTableRowItem}>{getDateTimeString(invoice.sentDate)}</td>
                            <td className={styles.invoicesTableRowItem}>
                              {
                                (loadingFile && loadingFile === invoice.id)?
                                <ClipLoader size={30} color={'#5603fc'} />
                                :
                                <div className={styles.button} onClick={(e)=>{ deleteInvoice(invoice.id) }}>
                                  Delete <Emoji name="prohibited" className={styles.emoji} width={16} />
                                </div>
                              }
                            </td>
                          </tr>
                        )
                      })
                    }
                </table>
              </div>
            </div>
          </div>


          <PopupWindow title={<>New Invoice Upload <Emoji name="bookmark-tabs" className={styles.emoji} width={22} /></>} onClose={()=>{ setNewInvoiceWindowOpen(false) }} open={newInvoiceWindowOpen}>
            <div className={styles.newInvoiceContent}>
              <label for="logoInput">
                <div className={styles.fileButton} onClick={(e)=>{  }}>
                    Select File
                </div>
              </label>
              <br/>
              <input disabled={loadingFile} accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(e)=>{onInvoiceSelect(e)}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
              <div className={styles.fileLabel}>
                {newInvoiceFileName}
              </div>
              <br/>
              <TextInput placeholder={'Notes'} type={'email'} customStyle={{width:'320px',paddingTop:'10px',paddingBottom:'10px'}} onChange={(value)=>{setNotes(value)}} />
              <br/><br/>
              {
                (loadingFile)?
                <ClipLoader size={50} color={'#5603fc'} />
                :
                <div className={styles.invoicesButton} onClick={(e)=>{ uploadInvoice() }}>
                  Upload <Emoji name="cloud" className={styles.emoji} width={18} />
                </div>
              }
            </div>
          </PopupWindow>

          <PopupWindow windowStyles={{width:'70%', height:'80%', overflowY:'auto'}} title={<>Create & Send A New Invoice <Emoji name="bookmark-tabs" className={styles.emoji} width={22} /></>} onClose={()=>{ setInvoiceEditorOpen(false) }} open={invoiceEditorOpen}>
            <div className={styles.newInvoiceContent}>

              <div className={styles.invoiceColumns}>
                <div className={styles.invoiceColumn}>
                  <div className={styles.createInvoiceRowLeft}>
                    <div className={styles.questionLeft}>From (Name & Address) <input type="checkbox" id="checkbox1" name="checkbox1" checked={saveFromAddress} onChange={(event)=>{setSaveFromAddress(!saveFromAddress)}}></input> Save</div>
                    <textarea className={styles.addressInput} placeholder={'From'} value={invoiceFrom} onChange={(event)=>{ store.dispatch(authSlice.actions.setInvoiceFrom(event.target.value)) }} />
                    </div>
                    
                  <div className={styles.createInvoiceRowLeft}>
                    <div className={styles.questionLeft}>To (Name & Address)</div>
                    <textarea className={styles.addressInput} placeholder={'To'} value={newInvoiceToAddress} onChange={(event)=>{ setNewInvoiceToAddress(event.target.value) }} />
                  </div>

                </div>
                <div className={styles.invoiceColumn}>
                  <div className={styles.createInvoiceRowRight}>
                    <div className={styles.questionRight}>Invoice Name & Number</div>
                    <input type="text" className={styles.textInput} placeholder={'Invoice Name & Number'} value={newInvoiceName} onChange={(event)=>{ setNewInvoiceName(event.target.value) }} />
                  </div>
                  
                  <div className={styles.createInvoiceRowRight}>
                  <div className={styles.questionRight}>Sent Date</div>
                    <input type="text" className={styles.textInput} placeholder={'Invoice Date'} value={newInvoiceSentDate} onChange={(event)=>{ setNewInvoiceSentDate(event.target.value) }} />
                  </div>

                  <div className={styles.createInvoiceRowRight}>
                    <div className={styles.questionRight}>Due Date</div>
                    <input type="text" className={styles.textInput} placeholder={'Invoice Due Date'} value={newInvoiceDueDate} onChange={(event)=>{ setNewInvoiceDueDate(event.target.value) }} />
                  </div>

                  <div className={styles.createInvoiceRowRight}>
                    <div className={styles.questionRight}>Currency</div>
                    <select className="currencyInput" value={newInvoiceCurrency} onChange={(event)=>{ setNewInvoiceCurrency(event.target.value) }} >
                      {
                        currencies.map((currency)=>{
                          return <option value={currency.name}>{currency.name}</option>
                        })
                      }
                    </select>
                  </div>

                  <div className={styles.createInvoiceRowRight}>
                    <div className={styles.questionRight}>Sender VAT Number</div>
                    <input type="text" className={styles.textInput} placeholder={'Sender VAT Number'} value={newInvoiceSenderVAT} onChange={(event)=>{ setNewInvoiceSenderVAT(event.target.value) }} />
                  </div>

                  <div className={styles.createInvoiceRowRight}>
                    <div className={styles.questionRight}>Receiver VAT Number</div>
                    <input type="text" className={styles.textInput} placeholder={'Receiver VAT Number'} value={newInvoiceReceiverVAT} onChange={(event)=>{ setNewInvoiceReceiverVAT(event.target.value) }} />
                  </div>


                </div>
              </div>

              <br/><br/>
              <table className={styles.invoiceItemsTable}>
                {
                  invoiceItems.map((item,itemIndex)=>{
                    return(<tr>
                      <td>
                       {item.name}
                      </td>
                      <td>
                        {getCurrencySymbol(newInvoiceCurrency)} {item.price}
                      </td>
                      <td>
                        {item.quantity}
                      </td>
                      <td>
                        <div className={styles.removeButton} onClick={(e)=>{ removeInvoiceItem(itemIndex) }}>
                          Remove
                        </div>
                      </td>
                    </tr>)
                  })
                }
                <tr>
                  <td>
                    <input type="text" className={styles.invoiceItemTextBox} placeholder={'Product/ Service'} value={invoiceItemName} onChange={(event)=>{ setInvoiceItemName(event.target.value) }} />
                  </td>
                  <td>
                    <input type="number" className={styles.invoiceItemTextBox} placeholder={'Price'} value={invoiceItemPrice} onChange={(event)=>{ setInvoiceItemPrice(event.target.value) }} />
                  </td>
                  <td>
                    <input type="number" className={styles.invoiceItemTextBox} placeholder={'Quantity'} value={invoiceItemQuantity} onChange={(event)=>{ setInvoiceItemQuantity(event.target.value) }} />
                  </td>
                  <td>
                    <div className={styles.addButton} onClick={(e)=>{ addInvoiceItem() }}>
                      Add
                    </div>
                  </td>
                </tr>
              </table>
              <br/><br/>
              <div className={styles.createInvoiceRowRight}>
                  <div className={styles.questionRight}><b>Subtotal: {getCurrencySymbol(newInvoiceCurrency)}{getSubtotal()}</b></div>
                  <br/><br/>
                  <div className={styles.questionRight}>Tax %</div>
                  <input type={'number'} className={styles.textInput} placeholder={'Terms'} value={newInvoiceTax} onChange={(event)=>{ setNewInvoiceTax(event.target.value) }} />
                  <br/><br/>
                  <div className={styles.questionRight}><b>Total Due: {getCurrencySymbol(newInvoiceCurrency)}{getInvoiceTotal()}</b></div>
              </div>
              <br/><br/>
              <div className={styles.fullRow}>
                  <div className={styles.createInvoiceRowLeft}>
                    <div className={styles.questionLeft}>Notes</div>
                    <textarea className={styles.addressInput} placeholder={'Notes'} value={newInvoiceNotes} onChange={(event)=>{ setNewInvoiceNotes(event.target.value) }} />
                  </div>

                  <div className={styles.createInvoiceRowRight}>
                    <div className={styles.questionRight}>Terms</div>
                    <textarea className={styles.addressInput} placeholder={'Terms'} value={newInvoiceTerms} onChange={(event)=>{ setNewInvoiceTerms(event.target.value) }} />
                  </div>
              </div>
              <br/><br/>
              {
                (loadingFile)?
                <ClipLoader size={50} color={'#5603fc'} />
                :
                <div className={styles.invoicesButton} onClick={(e)=>{ createInvoice()  }}>
                  Create <Emoji name="sparkles" className={styles.emoji} width={18} />
                </div>
              }
            </div>
          </PopupWindow>
          
      </div>
    </EmojiProvider>
  )
}