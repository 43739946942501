import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

const content = () => {

  return (
    <EmojiProvider data={emojiData}>
      <div className={styles.container}>
        <div className={styles.title}>A Guide to Opening a Bank Account as an Expat in Spain</div>
        <div className={styles.paragraph}>
        Moving to Spain as an expatriate can be an exciting adventure, filled with new experiences and opportunities. <br/>
        One essential task on your to-do list will likely be opening a bank account, which will make managing your finances much easier. <br/>
        Here’s a step-by-step guide to help you navigate the process of creating a bank account in Spain.
        </div>
        <br/><br/>
        <iframe style={{alignSelf:'center'}} width="650" height="315" 
        src="https://www.youtube.com/embed/1ExvwhhEjdg?si=PoAJSCic_PxyGEoM" 
        title="Bank Application" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
        <br/><br/>
        <div className={styles.subtitle}>1. Choose The Right Bank</div>
        <div className={styles.paragraph}>
        Spain has a wide variety of banks, including large national institutions, regional banks, and international options. <br/>
        Some popular choices include:
        <br/>
        <list>
          <li>Banco Santander</li>
          <li>BBVA</li>
          <li>CaixaBank</li>
          <li>Bankia</li>
        </list>
        <br/>
        Consider what services are important to you—such as online banking, English-speaking staff, and branch locations—when selecting a bank.
        </div>

        <div className={styles.subtitle}>2. Types of Accounts</div>
        <div className={styles.paragraph}>
        Most banks offer different types of accounts, including:
        <br/>
        <list>
          <li><b>Current Accounts:</b> Ideal for everyday banking, allowing you to make deposits, withdrawals, and payments.</li>
          <li><b>Savings Accounts:</b> Designed for saving money, often offering interest on your balance.</li>
          <li><b>Joint Accounts: </b> Useful for couples or families who want to manage finances together.</li>
        </list>
        <br/>
        Choose the type of account that best suits your needs.
        </div>

        <div className={styles.subtitle}>3. Gather Required Documents</div>
        <div className={styles.paragraph}>
        To open a bank account in Spain, you will need to provide several documents. <br/>
        Requirements may vary slightly by bank, but generally, you’ll need:
        <br/>
        <list>
          <li><b>Passport or National ID:</b> </li>
          <li><b>NIE (Número de Identificación de Extranjero):</b> </li>
          <li><b>Proof of Address: </b> This can be a utility bill, rental contract, or any official document with your name and address.</li>
          <li><b>Proof of Income or Employment: </b> Some banks may ask for evidence of income, such as a work contract or payslips.</li>
        </list>
        </div>

        <div className={styles.subtitle}>4. Visit The Bank</div>
        <div className={styles.paragraph}>
        While some banks offer online account opening, it’s often recommended to visit a local branch, especially if you’re not fluent in Spanish. <br/>
        An in-person visit allows you to ask questions, understand account features, and receive guidance throughout the process. <br/>
        Be sure to bring all your documentation.
        </div>

        <div className={styles.subtitle}>5. Complete the Application</div>
        <div className={styles.paragraph}>
        Once at the bank, you’ll fill out an application form. Bank staff will assist you in English or Spanish, depending on their language skills. <br/>
        Review the terms and conditions of the account, including fees, withdrawal limits, and online banking features.
        </div>

        <div className={styles.subtitle}>6. Receive Your Bank Card and Online Access</div>
        <div className={styles.paragraph}>
        After your application is approved, you will typically receive your bank card within a few days to a couple of weeks, depending on the bank. <br/>
        Additionally, you’ll be set up with online banking access, allowing you to manage your account conveniently from anywhere.
        </div>

        <div className={styles.subtitle}>7. Understand Banking Fees and Services</div>
        <div className={styles.paragraph}>
        Be aware of any fees associated with your account, such as monthly maintenance fees, ATM withdrawal fees, or international transfer charges. <br/>
        Many banks offer fee-free accounts for students or those who maintain a minimum balance. <br/>
        Additionally, inquire about services like overdraft protection, credit cards, and investment options.
        </div>

        <div className={styles.subtitle}>8. Consider International Banking Needs</div>
        <div className={styles.paragraph}>
        If you plan to transfer money internationally or use your account frequently for foreign transactions, discuss options for international banking services, such as foreign currency accounts and lower fees for international transfers.
        </div>

        <div className={styles.paragraph}>
        Opening a bank account as an expat in Spain is a straightforward process when you know what to expect. <br/>
        By choosing the right bank, gathering necessary documents, and understanding the services and fees, you’ll be well on your way to managing your finances in your new home. <br/>
        With a local bank account, you can enjoy all the conveniences of life in Spain while ensuring your financial transactions are seamless and efficient.
        </div>

      

      </div>
    </EmojiProvider>
  )
}

export default content

