import axios from 'axios';
import env from '../env';
import handleError from './handleError';
import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");

const get = async(endpoint)=>{
  const res = await axios.get(env.BASE_URL+endpoint,{headers: { Authorization: `Bearer ${token}` }}).catch(handleError)
  return res
}
const post = async(endpoint,body)=>{
  const res = await axios.post(env.BASE_URL+endpoint,{...body},{headers: { Authorization: `Bearer ${token}`,'Content-Type':(body.usesFiles)?'multipart/form-data':undefined }}).catch(handleError)
  return res
}

const put = async(endpoint,body)=>{
  const token = cookies.get("token");
  console.log(token)
  const res = await axios.put(env.BASE_URL+endpoint,{...body},{headers: { Authorization: `Bearer ${token}` }}).catch(handleError)
  return res
}

//JS Keyword delete is reserved
const deleteEntity = async(endpoint)=>{
  const res = await axios.delete(env.BASE_URL+endpoint,{headers: { Authorization: `Bearer ${token}` }}).catch(handleError)
  return res
}

export {get, post, put, deleteEntity}