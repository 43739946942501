import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import getSuggestedDocuments from '../../utils/getSuggestedDocuments';
import {nomadVisaAvailable, nomadVisaContent, nomadVisaDocuments } from '../../utils/nomadVisaAvailable';
import store from '../../store'
import { ClipLoader } from "react-spinners"
import principalSlice from '../../slices/principalSlice'
import authSlice from '../../slices/authSlice'
import { PopupWindow } from '../PopupWindow'

// Styles
import styles from './index.module.scss';

// Assets
import documents from '../../images/flow-icon-4.png';
import check from '../../images/check.png'
import chevron from '../../images/chevron.svg'
import info from '../../images/information-white.svg'
import download from '../../images/download.svg'

export const DocumentsOverview = ({setInfoWindowContent, setDocumentId})=>{

  const user = useSelector(state => state.authSlice?.user)
  const uploadedDocuments = useSelector(state => state.authSlice?.uploadedDocuments)
  const requirement = useSelector(state => state.authSlice?.requirement)
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const submissions = useSelector(state => state.authSlice?.submissions)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [indexes, setIndexes] = useState(getSuggestedDocuments({passportCountries:JSON.parse(user.passports || '[]'),requirement}).sections.map((section)=>{ return 0 }))
  const [submissionDocument, setSubmissionDocument] = useState(null)
  const [nomadVisaOpen, setNomadVisaOpen] = useState(false)
  
  useEffect(() => {
    store.dispatch(principalSlice.async$.getMessages())
  }, [])

  const submitDocumentsForReview = (document)=>{
    setSubmissionDocument({name:document.name,to:document.to,id:document.id})
  }

  const submitForms = async()=>{
    setLoading(true)
    let res = await store.dispatch(authSlice.async$.submitForms({...submissionDocument}))
    if(res.payload?.success){
      setSubmitted(true)
    }
    setLoading(false)
  }

  const submitDigitalNomadVisa = async()=>{
    setLoading(true)
    let res = await store.dispatch(authSlice.async$.submitForms({name:'Digital Nomad Visa',id:'digitalnomadvisa',to:'lawyer'}))
    if(res.payload?.success){
      setSubmitted(true)
    }
    setLoading(false)
  }


  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.tile}>
          <div className={styles.title}><img src={documents} className={styles.titleIcon} />Next Steps: Upload Your Documents</div>
          <br/>
          {
            (false)?
            <div className={styles.content}>

            </div>
            :
            <div className={styles.content}>
              <div className={styles.tileSubtitle}>You can upload your documents to get started. You can then submit them for processing by our team. If you have an urgent query you can also get quick answers with our AI.</div>
              <div className={styles.documents}>
                {
                  (nomadVisaAvailable({passportCountries:JSON.parse(user.passports || '[]'),employerCountry:user.employerCountry,estimatedIncome}))?
                  <div className={styles.nomadDocument}>
                    <div className={styles.nomadDocumentsName}>You May Be Applicable For The Spanish Digital Nomad Visa!</div>
                    
                    <img className={styles.info} src={info} onClick={(e)=>{ setInfoWindowContent(nomadVisaContent()) }}></img>
                    <div className={styles.nomadVisaButton} onClick={(e)=>{ setNomadVisaOpen(true) }}>
                      Apply <Emoji name="rocket" className={styles.emoji} width={16} />
                    </div>
                    
                  </div>
                  :
                  <></>
                }

                {
                  getSuggestedDocuments({passportCountries:JSON.parse(user.passports || '[]'),requirement}).sections.map((section, sectionIndex)=>{
                    return(
                      <div className={styles.documentsSection}>
                        <div className={styles.documentsSectionTitle}>
                          {section.name}
                          <div className={styles.documentsButtonRow}>
                            <div className={styles.documentsButton} onClick={(e)=>{ setInfoWindowContent(section.information) }}>
                              More Info
                            </div>
                            <div className={styles.nomadDocumentsStartButton} onClick={(e)=>{ 
                              let tmp = [...indexes]
                              tmp[sectionIndex] = !tmp[sectionIndex]
                              setIndexes(tmp)
                             }}>
                              {(getSuggestedDocuments({passportCountries:JSON.parse(user.passports || '[]'),requirement}).documents.filter((document)=>{
                                return document.section === section.id && (uploadedDocuments?.includes(document.id))
                              }).length)?
                                <>
                                Continue <img className={styles.chevron} src={chevron}></img>
                                </>
                                :
                                <>
                                Start Now <img className={styles.chevron} src={chevron}></img>
                                </>
                              }
                              
                            </div>
                          </div>
                        </div>
                      {
                        (indexes[sectionIndex])?
                          <>
                            {
                              getSuggestedDocuments({passportCountries:JSON.parse(user.passports || '[]'),requirement}).documents.filter((document)=>{
                                return document.section === section.id
                              }).map((document)=>{

                                if(document.type === 'button'){
                                  return(
                                  <div className={styles.document}>
                                    <div className={styles.documentsName}>{document.name}</div>
                                    <div className={styles.nomadDocumentsButton} onClick={(e)=>{ window.open(document.buttonLink, '_blank').focus() }}>
                                      {document.buttonTitle}
                                    </div>
                                  </div>
                                  )
                                }

                                return(
                                  <div className={styles.document}>
                                    <div className={styles.documentsName}>{document.name}</div>
                                    {(document.download)?<img className={styles.download} src={download} onClick={(e)=>{window.open(document.download, '_blank').focus()}} />:<></>}
                                    {
                                      (!uploadedDocuments?.includes(document.id))?
                                      <div className={styles.nomadDocumentsButton} onClick={(e)=>{setDocumentId(document.id) }}>
                                        Upload
                                      </div>
                                      :
                                      <img src={check} className={styles.check} />
                                    }
                                  </div>
                                )
                              })
                            }
                            {
                            (getSuggestedDocuments({passportCountries:JSON.parse(user.passports || '[]'),requirement}).documents.filter((document)=>{
                              return document.section === section.id && document.type !== 'button' && !uploadedDocuments?.includes(document.id)
                            }).length)?
                            <div className={styles.noSubmitButton}>Submit For Review</div>
                             :
                            (submissions?.includes(section.id))?<div className={styles.noSubmitButton}>Documents Submitted</div>:<div className={styles.submitButton} onClick={(e)=> submitDocumentsForReview(section) }>Submit For Review</div>
                            }
                          </>
                        :
                        <></>
                      }
                      
                    </div>
                    )
                  })
                }
              </div>
            </div>
          }
        </div>

      <PopupWindow windowStyles={{width:'60%'}} title={submissionDocument?.name+' Documents Submission'} onClose={()=>{ setSubmissionDocument(null) }} open={submissionDocument}>
        {
          (submitted)?
          <>
          <div className={styles.submissionText}>
            <img src={check} className={styles.checkIcon} /><br/><br/>
            Congratulations, you're documents were submitted!<br/><br/>
            We will be in touch soon.
          </div>
          </>
          :
          <>
          <div className={styles.submissionText}>
            <Emoji name="rocket" className={styles.emoji} width={48} /><br/><br/>
            Congratulations, you are ready to submit your documents for review by {submissionDocument?.to === 'laywer'?'a':'an'} {submissionDocument?.to}. <br/><br/>
            Once your assigned {submissionDocument?.to} has assessed it, you may need to book a consultation with them. <br/><br/>
            You can also manage your documents in the 'My Documents' page.<br/><br/>
          </div>
          {
            (loading)?
            <div className={styles.loadingContainer}>
              <ClipLoader size={50} color={'#5603fc'} />
            </div>
            :
            <div className={styles.submitButtonLarge} onClick={(e)=> submitForms()}>Submit</div>
          }
        </>
        }
      </PopupWindow>

      <PopupWindow windowStyles={{width:'60%'}} title={'Digital Nomad Visa Forms'} onClose={()=>{ setNomadVisaOpen(null) }} open={nomadVisaOpen}>
        {
          (submitted)?
          <>
          <div className={styles.submissionText}>
            <img src={check} className={styles.checkIcon} /><br/><br/>
            Congratulations, you're documents were submitted to our accountant!<br/><br/>
            We will be in touch soon.
          </div>
          </>
          :
          <>
          <div className={styles.submissionText}>
            Before submitting, please ensure all your details are correct in the 'My Details' section, otherwise the Digital Nomad Visa may not apply to you. 
            You can submit your Digital Nomad Visa application documents for review by a lawyer below. 
            Once your assigned lawyer has assessed it, you may need to book a consultation with them to confirm your ability to apply.
            You can also manage your documents in the 'My Documents' page.<br/><br/>
          </div>
          <br/>
          <div className={styles.submissionTextBold}>
            Still have queries before submitting? <div className={styles.queriesButton} onClick={(e)=> { document.location = './messages'}}>Message Your Lawyer</div>
          </div>
          
          <br/><br/>
          {
            nomadVisaDocuments.map((document)=>{
              return(
                <div className={styles.document}>
                  <div className={styles.documentsName}>{document.name}</div>
                  {(document.download)?<img className={styles.download} src={download} onClick={(e)=>{window.open(document.download, '_blank').focus()}} />:<></>}
                  {
                    (!uploadedDocuments?.includes(document.id))?
                    <div className={styles.nomadDocumentsButton} onClick={(e)=>{setDocumentId(document.id) }}>
                      Upload
                    </div>
                    :
                    <img src={check} className={styles.check} />
                  }
                </div>
              )
            })
          }
          <br/>
          {
            (loading)?
            <div className={styles.loadingContainer}>
              <ClipLoader size={50} color={'#5603fc'} />
            </div>
            :
            (nomadVisaDocuments.filter((document)=>{
              return !uploadedDocuments?.includes(document.id)
            }).length)?<div className={styles.noSubmitButtonLarge} onClick={(e)=> { submitDigitalNomadVisa() }}>Submit For Review</div>:<div className={styles.submitButtonLarge} onClick={(e)=> {}}>Submit For Review</div>
          }
          <br/>
        </>
        }
      </PopupWindow>

    </EmojiProvider>
   
  )


}
