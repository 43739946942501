import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import styles from './styles.module.scss';

export const Loading = () => {
  const [password, setPassword] = useState(null);
  const [error,setError] = useState('');
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    
  },[]);



  return (
    <div className={styles.screen}>
      <div className={styles.loader}>
        <ClipLoader size={50} color={'#5603fc'} />
      </div>
    </div>
  )
}