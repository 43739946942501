import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import store from '../../store'
import authSlice from '../../slices/authSlice'

import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

export const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState(null);
  const error = useSelector(state => state.authSlice?.error);
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    
  },[]);

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();
      authSlice.actions.setError('')
      const res = await store.dispatch(authSlice.async$.attemptLogin({email, password}))

    },
    [email, password]
  )



  return (
    <EmojiProvider data={emojiData}>
    <div className="global-container">
        <div className={styles.loginLogoContainer}>
          <img src={require('../../images/logo.png')} className={styles.loginLogo} />
          <div className={styles.loginLogoText}>SimplyNomad</div>
        </div>
        <div className="global-login-card-view" id={styles.loginPanel}>
          <div id={styles.loginHeaderMain}>Welcome Back!</div>
          <br/>
          <TextInput placeholder={'Email'} type={'email'} onChange={(value)=>{setEmail(value)}} />
          <TextInput placeholder={'Password'} type={'password'} onChange={(value)=>{setPassword(value)}} />
          <SubmitButton loading={loading} title={'Log In'} emoji={<Emoji name="rocket" className="emoji" width={22} />} onClick={onSubmit} />
          <br/>
          <div className={styles.loginError}>{error}</div>
          <br/>
          <div id={styles.loginLinks}>
            <a className={styles.loginLink} href="./signup">Sign Up </a> |
            <a className={styles.loginLink} href="./forgot-password"> Forgot Your Password?</a> |
            <a className={styles.loginLink} href="./accountant-login"> Accountant Login</a>
          </div>
        </div>

        <div className={styles.loginCopyright}>

          	&copy; Copyright Conquer Technologies Ltd. 2024
        </div>
    </div>
    </EmojiProvider>
  )
}